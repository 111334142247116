import React from "react";

function EternalLife() {
    return (
        <>
        <h3>Revelation 2:10, 11</h3>
        <p>
                    10 Do not be afraid of the sufferings that are coming to you. Look, the devil will send some of you to prison to put you to the test, 
                    and you must face hardship for ten days.<br/>
                    Even if you have to die, keep faithful, and <b>I will give you the crown of life</b> for your prize.<br/>
                    11 Let anyone who can hear, listen to what the Spirit is saying to the churches; for those who prove victorious <b>will come to no harm from the second death</b>.
                </p>
        </>
    )
}

export default EternalLife;