import React from "react"
import { sign, intention, amen } from "../Novena"

export const MonicaItems = [
    { label: 'Day 1', key: 1, children: <Monica props={[{ key: 1 }]} /> },
    { label: 'Day 2', key: 2, children: <Monica props={[{ key: 2 }]} /> },
    { label: 'Day 3', key: 3, children: <Monica props={[{ key: 3 }]} /> },
    { label: 'Day 4', key: 4, children: <Monica props={[{ key: 4 }]} /> },
    { label: 'Day 5', key: 5, children: <Monica props={[{ key: 5 }]} /> },
    { label: 'Day 6', key: 6, children: <Monica props={[{ key: 6 }]} /> },
    { label: 'Day 7', key: 7, children: <Monica props={[{ key: 7 }]} /> },
    { label: 'Day 8', key: 8, children: <Monica props={[{ key: 8 }]} /> },
    { label: 'Day 9', key: 9, children: <Monica props={[{ key: 9 }]} /> }
]

const var1 = `Dear Saint Monica, you were once the mournful mother of a prodigal son. 
Your faithfulness to prayer brought you and your son so close to God that you are now with him in eternity. 
By your intercession and God’s grace, your son St. Augustine became a great and venerable Saint of the Church. 
Please take my request to God with the same fervor and persistence with which you prayed for your own son.`
const var2 = `With your needs, worries and anxieties, you threw yourself on the mercy and providence of God. 
Through sorrow and pain, you constantly devoted yourself to God. Pray for me, that I might join you in such a deep faith in God’s goodness and mercy.`
const var3 = `Above all, dear Saint Monica, pray for me, that I may, like your son, turn from my sin and become a great saint for the glory of God.`

function Monica(data) {
    return (
        <p>
            {sign}<br /><br />

            {var1}<br />

            <br /><i>{intention}</i><br /><br />

            {var2}<br /><br/>
            {var3}<br /><br />

            {amen}<br /><br />
            {sign}<br />
        </p>
    )
}