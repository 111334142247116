import { createSlice } from "@reduxjs/toolkit";

export const loginSlice = createSlice({
    name: 'login',
    initialState: {
        value: null
    },
    reducers: {
        login: state => {
            state.value = 'Hi Name'
            console.log('dispatch login'+state.value)
        },
        logout: state => {
            state.value = null
            console.log('dispatch login')
        }
    }
})

export const {login, logout} = loginSlice.actions

export default loginSlice.reducer