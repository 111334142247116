import React from "react"
import { Collapse, Tabs, Row, Col } from 'antd'
import { CaretRightOutlined } from '@ant-design/icons'

import { ChristTheKingItems } from './Novenas/ChristTheKing'
import { JudeItems } from "./Novenas/Jude"
import { ThereseItems } from './Novenas/Therese'
import { NativityItems } from "./Novenas/Nativity"
import { MonicaItems } from "./Novenas/Monica"
import { PeregrineItems } from "./Novenas/Peregrine"
import { AnneItems } from "./Novenas/Anne"
import { SacredHeartOfJesusItems } from "./Novenas/SacredHeartOfJesus"
import { HolySpiritItems } from "./Novenas/HolySpirit"
import { MaryItems } from "./Novenas/Mary"
import { DivineMercyItems } from "./Novenas/DivineMercy"
import { JosephItems } from "./Novenas/Joseph"
import { MichaelItems } from "./Novenas/Michael"

const { Panel } = Collapse;

export const sign = 'In the name of the Father, and of the Son, and of the Holy Spirit. Amen.'
export const intention = '[Mention your intentions here]'
export const amen = 'Amen'

function NovenaTemplate(data) {
    return (
        <Row>
            <Col span={20}>
                <Tabs
                    defaultActiveKey="1"
                    type="card"
                    size='small'
                    items={data.items} />
            </Col>
            <Col span={4}>
                {data && data.img ? <img alt="SaintImage" src={require('../images/' + data.img)} style={{ height: '150px', width: '150px', padding: '5px' }}></img> : <></>}
            </Col>
        </Row>

    )
}

function Novena() {
    return (
        <>
            <Collapse accordion
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
                <Panel header="Christ the King Novena" key="1">
                    <NovenaTemplate items={ChristTheKingItems} img={'Christ.jpeg'} />
                </Panel>
                <Panel header="St.Jude Novena" key="2">
                    <NovenaTemplate items={JudeItems} img={'Saint_Jude.jpg'} />
                </Panel>
                <Panel header="St.Therese Novena (Little flower)" key="3">
                    <NovenaTemplate items={ThereseItems} img={'St_Therese.png'} />
                </Panel>
                <Panel header="Nativity of the Blessed Virgin Mary Novena" key="4">
                    <NovenaTemplate items={NativityItems} />
                </Panel>
                <Panel header="St.Monica Novena-August 18th" key="5">
                    <NovenaTemplate items={MonicaItems} img={'Saint_Augustine_and_Saint_Monica.jpg'} />
                </Panel>
                <Panel header="St.Anne Novena" key="6">
                    <NovenaTemplate items={AnneItems} />
                </Panel>
                <Panel header="Novena to the Sacred Heart of Jesus" key="7">
                    <NovenaTemplate items={SacredHeartOfJesusItems} />
                </Panel>
                <Panel header="Novena to the Holy Spirit" key="8">
                    <NovenaTemplate items={HolySpiritItems} />
                </Panel>
                <Panel header="Mary Undoer of Knots Novena" key="9">
                    <NovenaTemplate items={MaryItems} />
                </Panel>
                <Panel header="Divine Mercy Novena" key="10">
                    <NovenaTemplate items={DivineMercyItems} />
                </Panel>
                <Panel header="St.Joseph Novena" key="11">
                    <NovenaTemplate items={JosephItems} />
                </Panel>
                <Panel header="St.Michael the Archangel Novena" key="12">
                    <NovenaTemplate items={MichaelItems} />
                </Panel>
                <Panel header="St.Peregrine Novena" key="13">
                    <NovenaTemplate items={PeregrineItems} img={'St_Peregrine.jpg'} />
                </Panel>
                <Panel header="Christmas Novena" key="14">

                </Panel>
                <Panel header="Immaculate Conception Novena" key="15">

                </Panel>
                <Panel header="The Novena for Marriage & Family" key="16">

                </Panel>
                <Panel header="Novena to Saint Peter and Paul" key="17">

                </Panel>
                <Panel header="The Novena for Difficult Times" key="18">

                </Panel>
                <Panel header="Novena to the Holy Spirit for Pentecost" key="19">

                </Panel>
                <Panel header="St.Rita Novena" key="20">

                </Panel>
                <Panel header="The St.Therese of Lisieux Novena" key="21">

                </Panel>
                <Panel header="Novena to Saint Louis and Zelie Martin" key="22">

                </Panel>
                <Panel header="Our Lady of Lourdes Novena" key="23">

                </Panel>
                <Panel header="The Saint Maria Goretti Novena" key="24">

                </Panel>

                <Panel header="The St.Joseph, Husband of Mary Novena" key="25">

                </Panel>
                <Panel header="The Novena to Our Lady of Fatima" key="26">

                </Panel>
            </Collapse>
            <br />
            <p>Reference: <i>https://www.praymorenovenas.com/</i></p>
            <br /><br />
        </>
    )
}

export default Novena;
/*
<Panel header="" key="27">

                </Panel>
                <Panel header="" key="28">

                </Panel>
                <Panel header="" key="29">

                </Panel>
                <Panel header="" key="30">

                </Panel>
                <Panel header="" key="31">

                </Panel>
                <Panel header="" key="32">

                </Panel>

                <Panel header="" key="33">

                </Panel>
                <Panel header="" key="34">

                </Panel>
                <Panel header="" key="35">

                </Panel>
                <Panel header="" key="36">

                </Panel>
                <Panel header="" key="37">

                </Panel>
                <Panel header="" key="38">

                </Panel>
                <Panel header="" key="39">

                </Panel>
                <Panel header="" key="40">

                </Panel>

                <Panel header="" key="41">

                </Panel>
                <Panel header="" key="42">

                </Panel>
                <Panel header="" key="43">

                </Panel>
                <Panel header="" key="44">

                </Panel>
                <Panel header="" key="45">

                </Panel>
                <Panel header="" key="46">

                </Panel>
                <Panel header="" key="47">

                </Panel>
                <Panel header="" key="48">

                </Panel>

                <Panel header="" key="49">

                </Panel>
                <Panel header="" key="50">

                </Panel>
                <Panel header="" key="51">

                </Panel>
                <Panel header="" key="52">

                </Panel>
                <Panel header="" key="53">

                </Panel>
                <Panel header="" key="54">

                </Panel>
                <Panel header="" key="55">

                </Panel>
                <Panel header="" key="56">

                </Panel>

                <Panel header="" key="57">

                </Panel>
                <Panel header="" key="58">

                </Panel>
                */