import React from 'react';
import { Carousel } from 'antd';
import { SoundOutlined, GiftOutlined, ReadOutlined, AlertOutlined, BulbOutlined, FireOutlined, ProfileOutlined, LoadingOutlined } from '@ant-design/icons';

import Praising from '../Praise/Praising';

import DailyPrayer from '../Prayers/DailyPrayer';
import Novena from '../Prayers/Novena';
import Healing from '../Prayers/Healing';
import Promises from '../Prayers/Promises';
import AgainstFear from '../Prayers/AgainstFear';

import RosaryMalayalam from "../Rosary/RosaryMalayalam";
import RosaryEnglish from "../Rosary/RosaryEnglish";
import HolyMass from '../Rosary/HolyMass';

import JoyfullyBig from '../Inspiration/JoyfulllyBig';
import AudioBible from '../Inspiration/AudioBible';
import Retreat from '../Inspiration/Retreat';

import Charity from '../Insights/Charity';
import Faith from '../Insights/Faith';

import Providence from '../Wisdom/Providence';
import Commandments from '../Wisdom/Commandements';
import Tithe from '../Wisdom/Tithe';
import Thanksgiving from '../Wisdom/Thanksgiving';
import Pride from "../Wisdom/Pride";

import Woman from '../Menu/Woman';
import EternalLife from '../Menu/EternalLife';
import Prayer from '../Prayers/Prayer';
import Speech from '../Menu/Speech';
import Saints from '../Menu/Saints/Saints';

export function showCarousal() {

    const contentStyle = {
        height: '68px',
        color: '#fff',
        lineHeight: '30px',
        textAlign: 'center',
        fontWeight: 350
    };

    return <>
        <Carousel style={{ height: '68px' }} autoplay dots={false}>
            <div>
                <h3 style={contentStyle}>For look, the kingdom of God is among you. Luke 17:21</h3>
            </div>
            <div>
                <h3 style={contentStyle}>His inspiration will lie in fearing Yahweh. Isaiah 11:3</h3>
            </div>
            <div>
                <h3 style={contentStyle}>His judgement will not be by appearances. Isaiah 11:3</h3>
            </div>
            <div>
                <h3 style={contentStyle}>He will judge the weak with integrity Isaiah 11:4</h3>
            </div>
            <div>
                <h3 style={contentStyle}>What, then, will anyone gain by winning the whole world and forfeiting his life? Matthew 16:26</h3>
            </div>
            <div>
                <h3 style={contentStyle}>For God everything is possible. Mark 10:27</h3>
            </div>
            <div>
                <h3 style={contentStyle}>Hosanna! Blessed is he who is coming in the name of the Lord! Mark 11:9</h3>
            </div>
            <div>
                <h3 style={contentStyle}>Woman, you have great faith. Let your desire be granted. Matthew 15:28</h3>
            </div>
        </Carousel>
    </>
}

export const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export const items = [
    {
        label: "Praise", key: '1', icon: <SoundOutlined />
    },
    {
        label: "Prayers", key: '2', icon: <ReadOutlined />, children: [
            { label: "Daily Prayers", key: '2.1' },
            { label: "Novenas", key: '2.2' },
            { label: "Against Fear", key: '2.3' },
            { label: "Promises", key: '2.4' },
            { label: "Healing", key: '2.5' },
            { label: "Prayer", key: '2.6' }
        ]
    },
    {
        label: "Wisdom", key: '3', icon: <GiftOutlined />, children: [
            { label: "God's providence", key: '3.1' },
            { label: "Commandments", key: '3.2' },
            { label: "Tithe", key: '3.3' },
            { label: "Thanksgiving", key: '3.4' },
            { label: "Pride", key: '3.5' }
        ]
    },
    {
        label: "Insights", key: '4', icon: <AlertOutlined />, children: [
            { label: "Charity", key: '4.1' },
            { label: "Bondage", key: '4.2' },
            { label: "Confession", key: '4.3' },
            { label: "Holy Spirit", key: '4.4' },
            { label: "Faith", key: '4.5' }
        ]
    },
    {
        label: "Inspiration", key: '5', icon: <BulbOutlined />, children: [
            { label: "Joyfully Big", key: '5.1' },
            { label: "Audio Bible", key: '5.2' },
            { label: "Retreat", key: '5.3' }
        ]
    },
    {
        label: "Rosary & Mass", key: '6', icon: <FireOutlined />, children: [
            { label: "English", key: '6.1' },
            { label: "Malayalam", key: '6.2' },
            { label: "Holy Mass", key: '6.3' }
        ]
    },
    {
        label: "Menu", key: '7', icon: <ProfileOutlined />, children: [
            { label: "Children", key: '7.1' },
            { label: "Woman", key: '7.2' },
            { label: "Eternal Life", key: '7.3' },
            { label: "Speech", key: '7.4' },
            { label: "Saints", key: '7.5' }
        ]
    }
]

export const displayPages = key => {
    switch (key) {
        case '1':
            return <Praising />
        case '2.1':
            return <DailyPrayer />
        case '2.2':
            return <Novena />
        case '2.3':
            return <AgainstFear />
        case '2.4':
            return <Promises />
        case '2.5':
            return <Healing />
        case '2.6':
            return <Prayer />
        case '3.1':
            return <Providence />
        case '3.2':
            return <Commandments />
        case '3.3':
            return <Tithe />
        case '3.4':
            return <Thanksgiving />
        case '3.5':
            return <Pride />
        case '4.1':
            return <Charity />
        case '4.5':
            return <Faith />
        case '5.1':
            return <JoyfullyBig />
        case '5.2':
            return <AudioBible />
        case '5.3':
            return <Retreat />
        case '6.1':
            return <RosaryEnglish />
        case '6.2':
            return <RosaryMalayalam />
        case '6.3':
            return <HolyMass />
        case '7.2':
            return <Woman />
        case '7.3':
            return <EternalLife />
        case '7.4':
            return <Speech />
        case '7.5':
            return <Saints />
        default:
            return <></>
    }
}
