import React from "react";
import { Descriptions } from 'antd';

function Healing() {
    return (
        <>
            <Descriptions title="Isaiah 38:16">
                <Descriptions.Item>
                    The Lord is over them; they live, and everything in them lives by His spirit. You will cure me. Restore me to life.
                </Descriptions.Item>
            </Descriptions>
            <Descriptions title="Jeremiah 17:14">
                <Descriptions.Item>
                    Heal me, Yahweh, and I shall be healed, save me, and I shall be saved, for you are my praise.
                </Descriptions.Item>
            </Descriptions>
        </>
    )
}

export default Healing;