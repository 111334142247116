import React from "react";
import { Descriptions } from 'antd';

function Woman() {
    return (
        <>
            <Descriptions title="Good Wife (Ecclesiasticus 26:1-4, 13-18)">
                <Descriptions.Item>
                    1 How blessed is the husband of a really good wife; the number of his days will be doubled.<br />
                    2 A perfect wife is the joy of her husband, he will live out the years of his life in peace.<br />
                    3 A good wife is the best of portions, reserved for those who fear the Lord;<br />
                    4 rich or poor, their hearts will be glad, their faces cheerful, whatever the season.<br />
                    <br />
                    13 The grace of a wife will charm her husband, her understanding will make him the stronger.<br />
                    14 A silent wife is a gift from the Lord, no price can be put on a well-trained character.<br />
                    15 A modest wife is a boon twice over, a chaste character cannot be over-valued.<br />
                    16 Like the sun rising over the mountains of the Lord, such is the beauty of a good wife in a well-run house.<br />
                    17 Like a lamp shining on the sacred lamp-stand, such is a beautiful face on a well-proportioned body.<br />
                    18 Like golden pillars on a silver base, such are shapely legs on firm-set heels.
                </Descriptions.Item>
            </Descriptions>
        </>
    )
}

export default Woman;