import React from "react";
import { Descriptions } from 'antd';

function Tithe() {
    return (
        <>
            <Descriptions title="Sirach 35:4-10">
                <Descriptions.Item>
                    4 Do not appear empty-handed in the Lord's presence; for all these things are due under the commandment.<br />
                    5 The offering of the upright graces the altar, and its savour rises before the Most High.<br />
                    6 The sacrifice of the upright is acceptable, its memorial will not be forgotten.<br />
                    7 Honour the Lord with generosity, do not stint the first-fruits you bring.<br />
                    8 Add a smiling face to all your gifts, and be cheerful as you dedicate your tithes.<br />
                    9 Give to the Most High as he has given to you, as generously as your means can afford;<br />
                    10 for the Lord is a good rewarder, he will reward you seven times over.
                </Descriptions.Item>
            </Descriptions>
            <Descriptions title="Tobit 1:6-8">
                <Descriptions.Item>
                    6 Often I was quite alone in making the pilgrimage to Jerusalem, fulfilling the Law that binds all Israel perpetually.
                    I would hurry to Jerusalem with the first yield of fruits and beasts, the tithe of cattle and the sheep's first shearings.<br />
                    7 I would give these to the priests, the sons of Aaron, for the altar.
                    To the Levites ministering at Jerusalem I would give my tithe of wine and corn, olives, pomegranates and other fruits.
                    Six years in succession I took the second tithe in money and went and paid it annually at Jerusalem.<br />
                    8 I gave the third to orphans and widows and to the strangers who live among the Israelites;
                    I brought it them as a gift every three years. When we ate,
                    we obeyed both the ordinances of the law of Moses and the exhortations of Deborah the mother of our ancestor Ananiel; for my father had dies and left me an orphan.
                </Descriptions.Item>
            </Descriptions>
        </>
    )
}

export default Tithe;