import React from "react";
import { Card, Descriptions } from 'antd';

const { Meta } = Card;

function Praising() {
    return (
        <>
            <Card
                cover={<img alt="worshipImage" src={require('../worship.jpg')} style={{ height: 500 }} />}>
                <Meta description='Amen. Praise and glory and wisdom, thanksgiving and honour and power and strength to our God for ever and ever. Amen. (Revelation 7:12)'></Meta>

            </Card>
            <br/><br/>
            <Descriptions title="Revelation 4:8">
                <Descriptions.Item>
                    Holy, Holy, Holy is the Lord God, the Almighty; who was, and is and is to come.
                </Descriptions.Item>
            </Descriptions>
            <Descriptions title="Psalms 150:6">
                <Descriptions.Item>
                    Let everything that breathes praise Yahweh. Alleluia!
                </Descriptions.Item>
            </Descriptions>
            <Descriptions title="Sirach 32:13">
                <Descriptions.Item>
                And for all this bless your Creator, who intoxicates you with his favours.
                </Descriptions.Item>
            </Descriptions>
            <Descriptions title="Isaiah 25:1">
                <Descriptions.Item>
                Yahweh, you are my God,<br/>
                I shall praise you to the heights,<br/>
                I shall praise your name;<br/>
                For you have accomplished marvels,<br/>
                plans long-conceived, faithfully, firmly.
                </Descriptions.Item>
            </Descriptions>
            <br/><br/>
        </>
    )
}

export default Praising;
