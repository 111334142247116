import React from "react";
import { Descriptions } from 'antd';

function Prayer() {
    return (
        <>
            <Descriptions title="The prayer of Hezekiah when King Sennacherib sent messengers againt him. (Isaiah 37:16-20)">
                <Descriptions.Item>
                    16 Yahweh Sabaoth, God of Israel, enthroned on the winged creatures, you alone are God of all the kingdoms of the world, you made heaven and earth.<br />
                    17 Give ear, Yahweh, and listen; open your eyes, Yahweh, and see! Hear the words of Sennacherib, who has sent to insult the living God.<br />
                    18 It is true, Yahweh, that the kings of Assyria have destroyed all the nations (and their countries); <br />
                    19 they have thrown their gods on the fire, for thesse were not gods but human artefacts - wood and stone - and hence they have destroyed them.<br />
                    20 But now, Yahweh our God, save us from his clutched, I beg you, and let all the kingdoms of the world know that you alone are God, Yahweh.
                </Descriptions.Item>
            </Descriptions>
            <Descriptions title="Tobias and Sarah praying for protection when they started their married life. (Tobit 8:5-7)">
                <Descriptions.Item>
                    5 You are blessed, O God of our fathers;<br />
                    blessed too is your name for ever and ever.<br />
                    Let the heavens bless you and all things you have made for evermore.<br />
                    6 You it was who created Adam, you it was who created Eve his wife to be his help and support;<br />
                    and from these two the human race was born.<br />
                    You it was who said, 'It is not right that the man should be alone; let us make him a helper like him.'<br />
                    7 And so I take my sister not for any lustful motive, but I do it in singleness of heart. Be kind enough to have pity on her and on me and bring us to old age together.
                </Descriptions.Item>
            </Descriptions>
            <br/><br/>
        </>
    )
}

export default Prayer;
