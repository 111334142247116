import React from "react";
import { Tabs } from 'antd';
import Deliverance from "../Prayers/DailyPrayers/Deliverance";
import Marriage from "../Prayers/DailyPrayers/Marriage";
import Protection from "../Prayers/DailyPrayers/Protection";
import Tongue from "../Prayers/DailyPrayers/Tongue";
import Praise from "../Prayers/DailyPrayers/Praise";
import Repentence from '../Prayers/DailyPrayers/Repentence';
import Strength from '../Prayers/DailyPrayers/Strength';
import Penance from '../Prayers/DailyPrayers/Penance';
import Assurance from '../Prayers/DailyPrayers/Assurance';
import NoFear from "./DailyPrayers/NoFear";
import InDanger from "./DailyPrayers/InDanger";

const prayerItems = [
    { label: 'Deliverance', key: 1, children: <Deliverance /> },
    { label: 'Praise', key: 2, children: <Praise /> },
    { label: 'Repentence', key: 3, children: <Repentence /> },
    { label: 'Protection', key: 4, children: <Protection /> },
    { label: 'Penance', key: 5, children: <Penance /> },
    { label: 'Strength', key: 6, children: <Strength /> },
    { label: 'Assurance', key: 7, children: <Assurance /> },
    { label: 'Tongue', key: 8, children: <Tongue /> },
    { label: 'Marriage', key: 9, children: <Marriage /> },
    { label: 'No fear', key: 10, children: <NoFear /> },
    { label: 'In Danger', key: 11, children: <InDanger /> }
]

function DailyPrayer() {
    return (
        <>
            <Tabs
                defaultActiveKey="1"
                type="card"
                size='small'
                items={prayerItems} />
        </>
    )
}

export default DailyPrayer;
