import React from "react";
import { Descriptions } from 'antd';

function Repentence() {
    return (
        <>
            <Descriptions title="Psalms 107:16-20">
                <Descriptions.Item>
                    16 He broke open gates of bronze and smashed iron bars.<br />
                    17 Fools for their rebellious ways wretched because of their sins,<br />
                    18 finding all found repungant, brought close to the gates of death - <br />
                    19 they cried to Yahweh in their distress; he rescued them from their plight, <br />
                    20 he sent out His word and cured them, and rescued their life from the abyss.
                </Descriptions.Item>
            </Descriptions>
        </>
    )
}

export default Repentence;