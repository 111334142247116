import React from "react";
import { Descriptions } from 'antd';

function Commandments() {
    return (
        <>
            <Descriptions title="Ten Commandments - Deuteronomy 5">
                <Descriptions.Item>
                    1 Moses called all Israel together and said to them, *Listen, Israel, to the laws and customs that I proclaim to you today. Learn them and take care to observe them.<br/>
                    2 *Yahweh our God made a covenant with us at Horeb.<br/>
                    3 Yahweh made this covenant not with our ancestors, but with us, with all of us alive here today.<br/>
                    4 On the mountain, from the heart of the fire, Yahweh spoke to you face to face,<br/>
                    5 while I stood between you and Yahweh to let you know what Yahweh was saying, since you were afraid of the fire and had not gone up the mountain. He said:<br/>
                    6 * *I am Yahweh your God who brought you out of Egypt, out of the place of slave-labour.<br/>
                    7 * *You will have no gods other than me.<br/>
                    8 * *You must not make yourselves any image or any likeness of anything in heaven above or on earth beneath or in the waters under the earth;<br/>
                    9 you must not bow down to these gods or serve them. For I, Yahweh your God, am a jealous God and I punish the parents* fault in the children, the grandchildren and the great*grandchildren, among those who hate me;<br/>
                    10 but I show faithful love to thousands, to those who love me and keep my commandments.<br/>
                    11 * *You must not misuse the name of Yahweh your God, for Yahweh will not leave unpunished anyone who uses his name for what is false.<br/>
                    12 * *Observe the Sabbath day and keep it holy, as Yahweh your God has commanded you.<br/>
                    13 Labour for six days, doing all your work,<br/>
                    14 but the seventh day is a Sabbath for Yahweh your God. You must not do any work that day, neither you, nor your son, nor your daughter, nor your servants*male or female*nor your ox, nor your donkey, nor any of your animals, nor the foreigner who has made his home with you;<br/>
                    15 so that your servants, male and female, may rest, as you do. Remember that you were once a slave in Egypt, and that Yahweh your God brought you out of there with mighty hand and outstretched arm; this is why Yahweh your God has commanded you to keep the Sabbath day.<br/>
                    16 * *Honour your father and your mother, as Yahweh your God has commanded you, so that you may have long life and may prosper in the country which Yahweh your God is giving you.<br/>
                    17 * *You must not kill.<br/>
                    18 * *You must not commit adultery.<br/>
                    19 * *You must not steal.<br/>
                    20 * *You must not give false evidence against your fellow.<br/>
                    21 * *You must not set your heart on your neighbour's spouse, you must not set your heart on your neighbour's house, or field, or servant*man or woman*or ox, or donkey or any of your neighbour's possessions.*<br/>
                    22 *These were the words Yahweh spoke to you when you were all assembled on the mountain. Thunderously, he spoke to you from the heart of the fire, in cloud and thick darkness. He added nothing, but wrote them on two tablets of stone which he gave to me.<br/>
                    23 *Now, having heard this voice coming out of the darkness, while the mountain was all on fire, you came to me, all of you, heads of tribes and elders,<br/>
                    24 and said, *Yahweh our God has shown us his glory and his greatness, and we have heard his voice from the heart of the fire. Today we have seen that God can speak with a human being and that person still live.<br/>
                    25 So why should we expose ourselves to death again? For this great fire might devour us if we go on listening to the voice of Yahweh our God, and then we should die.<br/>
                    26 For what creature of flesh could possibly live after hearing, as we have heard, the voice of the living God speaking from the heart of the fire?<br/>
                    27 Go nearer yourself and listen to everything that Yahweh our God may say, and then tell us everything that Yahweh our God has told you; we shall listen and put it into practice!*<br/>
                    28 *Yahweh heard what you were saying to me, and he then said to me, *I have heard what these people are saying. Everything they have said is well said.<br/>
                    29 If only their heart were always so, set on fearing me and on keeping my commandments, so that they and their children might prosper for ever!<br/>
                    30 Go and tell them to go back to their tents.<br/>
                    31 But you yourself stay here with me, and I shall tell you all the commandments, the laws and the customs which you are to teach them and which they are to observe in the country which I am giving them as their possession.*<br/>
                    32 *Keep them and put them into practice: such is Yahweh's command to you. Stray neither to right nor to left.<br/>
                    33 Follow the whole way that Yahweh has marked for you, and you will survive to prosper and live long in the country which you are going to possess.*<br/>
                </Descriptions.Item>
            </Descriptions>
            <Descriptions title="Ten Commandments - Exodus 20">
                <Descriptions.Item>
                    1 Then God spoke all these words. He said,<br/>
                    2 'I am Yahweh your God who brought you out of Egypt, where you lived as slaves.<br/>
                    3 'You shall have no other gods to rival me.<br/>
                    4 'You shall not make yourself a carved image or any likeness of anything in heaven above or on earth beneath or in the waters under the earth.<br/>
                    5 'You shall not bow down to them or serve them. For I, Yahweh your God, am a jealous God and I punish a parent's fault in the children, the grandchildren, and the great-grandchildren among those who hate me;<br/>
                    6 but I act with faithful love towards thousands of those who love me and keep my commandments.<br/>
                    7 'You shall not misuse the name of Yahweh your God, for Yahweh will not leave unpunished anyone who misuses his name.<br/>
                    8 'Remember the Sabbath day and keep it holy.<br/>
                    9 For six days you shall labour and do all your work,<br/>
                    10 but the seventh day is a Sabbath for Yahweh your God. You shall do no work that day, neither you nor your son nor your daughter nor your servants, men or women, nor your animals nor the alien living with you.<br/>
                    11 For in six days Yahweh made the heavens, earth and sea and all that these contain, but on the seventh day he rested; that is why Yahweh has blessed the Sabbath day and made it sacred.<br/>
                    12 'Honour your father and your mother so that you may live long in the land that Yahweh your God is giving you.<br/>
                    13 'You shall not kill.<br/>
                    14 'You shall not commit adultery.<br/>
                    15 'You shall not steal.<br/>
                    16 'You shall not give false evidence against your neighbour.<br/>
                    17 'You shall not set your heart on your neighbour's house. You shall not set your heart on your neighbour's spouse, or servant, man or woman, or ox, or donkey, or any of your neighbour's possessions.'<br/>
                    18 Seeing the thunder pealing, the lightning flashing, the trumpet blasting and the mountain smoking, the people were all terrified and kept their distance.<br/>
                    19 'Speak to us yourself,' they said to Moses, 'and we will obey; but do not let God speak to us, or we shall die.'<br/>
                    20 Moses said to the people, 'Do not be afraid; God has come to test you, so that your fear of him, being always in your mind, may keep you from sinning.'<br/>
                    21 So the people kept their distance while Moses approached the dark cloud where God was.<br/>
                    22 Yahweh said to Moses, 'Tell the Israelites this, "You have seen for yourselves how I have spoken to you from heaven.<br/>
                    23 You must not make gods of silver to rival me, nor must you make yourselves gods of gold.<br/>
                    24 "You must make me an altar of earth on which to sacrifice your burnt offerings and communion sacrifices, your sheep and cattle. Wherever I choose to have my name remembered, I shall come to you and bless you.<br/>
                    25 If you make me an altar of stone, do not build it of dressed stones; for if you use a chisel on it, you will profane it.<br/>
                    26 You must not go up to my altar by steps, in case you expose your nakedness on them." '
                </Descriptions.Item>
            </Descriptions>
            <Descriptions title="Jeremiah 7:5-7">
                <Descriptions.Item>
                    5 But if you really amend your behaviour and your actions, if you really treat one another fairly,<br />
                    6 if you do not exploit the stranger, the orphan and the widow, if you do not shed innocent blood in this place and if you do not follow other gods, to your own ruin,<br />
                    7 then I shall let you stay in this place, in the country I gave for ever to your ancestors of old.<br />
                    8 Look, you are putting your faith in delusive, worthless words!<br />
                    9 Steal, would you, murder, commit adultery, perjure yourselves, burn incense to Baal, follow other gods of whom you know nothing? -<br />
                    10 and then come and stand before me in this Temple that bears my name, saying: Now we are safe to go on doing all these loathsome things!<br />
                    11 Do you look on this Temple that bears my name as a den of bandits? I, at any rate, can see straight, Yahweh declares.
                </Descriptions.Item>
            </Descriptions>
            <br/><br/>
        </>
    )
}

export default Commandments;