import React from "react";
import YouTube from "../Helper/YouTube";
import { Badge, Card, Row, Col } from "antd";
import "./inspiration.css";

function JoyfullyBig() {
    return (
        <>
        <Row>
            <Col span={12}>
            <Badge.Ribbon text="Joy">
                <Card title="Inspiration From Shalom World" size="small">
                    <YouTube embedId='https://www.youtube.com/embed/6m9S8p9IJDg' />
                </Card>
            </Badge.Ribbon>
            <Badge.Ribbon text="Happy" color="volcano">
                <Card title="Inspiration From Shalom World" size="small">
                    <YouTube embedId='https://www.youtube.com/embed/fkoDaYSXaf4' />
                </Card>
            </Badge.Ribbon>
            </Col>
            <Col span={12}>
            <Badge.Ribbon text="Prayer" color="cyan">
                <Card title="Inspiration From Shalom World" size="small">
                    <YouTube embedId='https://www.youtube.com/embed/NAJc0bxIX-c' />
                </Card>
            </Badge.Ribbon>
            <Badge.Ribbon text="Trust" color="magenta">
                <Card title="Inspiration From Shalom World" size="small">
                <YouTube embedId='https://www.youtube.com/embed/qG6fjKvOfpU' />
                </Card>
            </Badge.Ribbon>
            </Col>
        </Row>
            
            <br />
            
            <br/><br/><br/>
        </>
    )
}

export default JoyfullyBig;