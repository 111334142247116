import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Layout, Menu, Row, Col } from 'antd';
import { showCarousal, items, displayPages } from './LandingHelper';
import DateTime from "../DateTime/DateTime";
import './Landing.css'

import { fetchSaints } from './LandingSlice';

const { Header, Content, Footer, Sider } = Layout;

function Landing() {
    
    const [openKeys, setOpenKeys] = useState([]);
    const [selectedMenu, setSelectedMenu] = useState('1');
    const status = useSelector(state => state.landing.status)
    const dispatch = useDispatch()
    const rootSubMenus = ['2','3','4','5','6','7']

    useEffect(() => {
    }, [])

    const onClickMenu = e => {
        setSelectedMenu(e.key);
        if(e.key==='7.5' && status==='yet'){
            dispatch(fetchSaints())
        }
        
    }

    function onOpenMenuChange(keys) {
        const latestOpenKey = keys.find(
            key => openKeys.indexOf(key) === -1
        )
        if(rootSubMenus.indexOf(latestOpenKey)===-1){
            setOpenKeys(keys)
        }else{
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    }

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider
                className='sideStyle'
                breakpoint="lg"
                collapsedWidth="0"
                onBreakpoint={(broken) => {
                    //console.log(broken);
                }}
                onCollapse={(collapsed, type) => {
                    //console.log(collapsed, type);
                }}
            >
                <Menu
                    theme="light"
                    mode="inline"
                    className='menuStyle'
                    multiple={false}
                    items={items}
                    openKeys={openKeys}
                    onOpenChange={onOpenMenuChange}
                    onClick={onClickMenu}
                    defaultSelectedKeys={selectedMenu}
                />
                <div className='versionStyle'>Version 1.0.3, 15-Dec-2023</div>
            </Sider>
            <Layout>
                <Header className="site-layout-sub-header-background" style={{ padding: 0 }}>
                    <Row style={{height: '64px', background: 'midnightblue'}}>
                        <Col style={{height: '64px' }} span={4}>
                        <img alt="Kingdom Of God" src={require('../logo.png')} style={{ height: '64px', width: '198px', padding: '5px' }}></img>
                        </Col>
                        <Col style={{height: '64px' }} span={17}>
                            {showCarousal()}
                        </Col>
                        {/*<Login />*/}
                    </Row>
                </Header>
                <Content style={{ margin: '88px 16px 0' }}>
                    <div className="site-layout-background" style={{ padding: 24, minHeight: '79vh' }}>
                        {displayPages(selectedMenu)}
                    </div>
                </Content>
                <Footer className='footerStyle'>{<DateTime />}</Footer>
            </Layout>
        </Layout>
    );
}

export default Landing;

// Novena - Christ the King
// send email - subscription for logged in user
// print pdf / word
// find who logged in 
//find width of window
//Birthday of Saints - and Baby names
// notifications