import React from "react"
import { sign, intention, amen } from "../Novena"


export const ChristTheKingItems = [
    { label: 'Day 1', key: 1, children: <ChristTheKingDay /> },
    { label: 'Day 2', key: 2, children: <ChristTheKingDay props={[{key:2}]} /> },
    { label: 'Day 3', key: 3, children: <ChristTheKingDay props={[{key:3}]} /> },
    { label: 'Day 4', key: 4, children: <ChristTheKingDay props={[{key:4}]} /> },
    { label: 'Day 5', key: 5, children: <ChristTheKingDay props={[{key:5}]} /> },
    { label: 'Day 6', key: 6, children: <ChristTheKingDay props={[{key:6}]} /> },
    { label: 'Day 7', key: 7, children: <ChristTheKingDay props={[{key:7}]} /> },
    { label: 'Day 8', key: 8, children: <ChristTheKingDay props={[{key:8}]} /> },
    { label: 'Day 9', key: 9, children: <ChristTheKingDay props={[{key:9}]} /> }
]


const var1 = 'Christ, our Savior and our King, renew in me allegiance to Your Kingship.'
const var2 = 'I pray for the grace to place You above the powers of this world in all things.'
const var3 = 'O Prince of Peace, may Your reign be complete in my life and in the life of the world. Christ, my King, please answer these petitions if they be in accordance with Your Holy Will…'
const var4 = 'As I reflect on Your second, glorious coming and the judgment of all mankind, I beg You to show me mercy and give me the grace to become a great saint. I pray that not only will I spend eternity with You but that You may use me – a sinner – to bring others into Your Kingdom for Your glory.'
const var5 = 'Christ the King, Your Kingdom come!'

const word1 = 'I pray for the grace to obey You before any civic authority.'
const word2 = 'I pray for the grace to fervently bring about Your Kingdom in my family and community.'
const word3 = 'I pray that You will reign in my mind.'
const word4 = 'I pray that You will reign in my heart.'
const word5 = 'I pray that You will reign in my will.'
const word6 = 'I pray that You will reign in my body.'
const word7 = 'I pray that You will reign throughout all the world.'
const word8 = 'I pray that You will reign in every area of my life.'

function ChristTheKingDay(data) {
    return (
        <p>
            {sign}<br /><br />

            {var1}<br />
            {var2}<br />

            {data!=null&&data.props!=null&& data.props[0]!=null && data.props[0].key!=null ? <> <br/>{setWord(data.props[0].key)}<br /><br/></> : <br/>} 

            {var3}<br />
            
            <br /><i>{intention}</i><br /><br />
            
            {var4}<br />
            
            {var5}<br />

            {amen}<br /><br />
            {sign}<br />
        </p>
    )
}

function setWord(key) {
    switch (key) {
        case 1:
            return;
        case 2:
            return word1;
        case 3:
            return word1+" "+word2;
        case 4:
            return word1+" "+word2+" "+word3;
        case 5:
            return word1+" "+word2+" "+word3+" "+word4;
        case 6:
            return word1+" "+word2+" "+word3+" "+word4+" "+word5;
        case 7:
            return word1+" "+word2+" "+word3+" "+word4+" "+word5+" "+word6;
        case 8:
            return word1+" "+word2+" "+word3+" "+word4+" "+word5+" "+word6+" "+word7;
        case 9:
            return word1+" "+word2+" "+word3+" "+word4+" "+word5+" "+word6+" "+word7+" "+word8;
        default:
            return;
    }
}