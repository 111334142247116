import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
    status: 'yet',//loading, success, failed
    saints: {
        0: null,
        1: null,
        2: null,
        3: null,
        4: null,
        5: null,
        6: null,
        7: null,
        8: null,
        9: null,
        10: null,
        11: null
    }
}

const monthDays = {
    1: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
    2: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29],
    3: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
    4: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30],
    5: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
    6: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30],
    7: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
    8: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
    9: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30],
    10: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
    11: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30],
    12: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]
}

export const fetchSaints = createAsyncThunk('http://localhost:8080/saints/getSaints/1', async () => {
    console.log('fetchSaints')
    let saints = {
        0: null,
        1: null,
        2: null,
        3: null,
        4: null,
        5: null,
        6: null,
        7: null,
        8: null,
        9: null,
        10: null,
        11: null
    }
    for (let i = 1; i < 13; i++) {
        //const url = 'http://kingdomofgod.co.in:8088/saints/getMonthSaints/'+i
        const url = 'http://localhost:8088/saints/getMonthSaints/'+i
        const response = await window.fetch(url)
        const data = await response.json()
        if(data!==null && data.length>0){
            const days = monthDays[i]
            let arr = []
            days.forEach(element => {
                arr.push(data.filter(e => e.day === element))
            });
            saints[i-1] = arr
        }
    }
    return saints
})



export const landingSlice = createSlice({
    name: 'landing',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(fetchSaints.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchSaints.fulfilled, (state, action) => {
                state.status = 'success'
                state.saints = action.payload
            })
            .addCase(fetchSaints.rejected, (state, action) => {
                state.status = 'failed'
            })
    }
})

export const { saintsAction } = landingSlice.actions

export default landingSlice.reducer