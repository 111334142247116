import React from "react"
import { Descriptions, Collapse, Tabs } from 'antd'

const { Panel } = Collapse

const rosary = key => {
    const items = [
        {label: '1-Onhaam Daiva Rahasiyam', key: 1, children: key === '1' ? mystery('1.1') : key === '2' ? mystery('2.1') : key === '3' ? mystery('3.1') : key === '4' ? mystery('4.1') : <></>},
        {label: '2-Randaam Daiva Rahasyam', key: 2, children: key === '1' ? mystery('1.2') : key === '2' ? mystery('2.2') : key === '3' ? mystery('3.2') : key === '4' ? mystery('4.2') : <></>},
        {label: '3-Moonhaam Daiva Rahasyam', key: 3, children: key === '1' ? mystery('1.3') : key === '2' ? mystery('2.3') : key === '3' ? mystery('3.3') : key === '4' ? mystery('4.3') : <></>},
        {label: '4-Naalaam Daiva Rahasyam', key: 4, children: key === '1' ? mystery('1.4') : key === '2' ? mystery('2.4') : key === '3' ? mystery('3.4') : key === '4' ? mystery('4.4') : <></>},
        {label: '5-Anjaam Daiva Rahsayam', key: 5, children: key === '1' ? mystery('1.5') : key === '2' ? mystery('2.5') : key === '3' ? mystery('3.5') : key === '4' ? mystery('4.5') : <></>}
    ]
    return (
        <>
        <Tabs
            defaultActiveKey="1"
            type="card"
            size='small'
            items={items} /><br></br>
            {mysteryPrayer()}
        </>
    )
}

const mysteryPrayer = () => {
    return (
        <><p><i>Swargasthanaaya (1 time)</i></p>
            <p><i>Nanma Niranja Mariyame (10 times)</i></p>
            <p><i>Fatima Prayer</i></p></>
    )
}

const mystery = key => {
    var text = ""
    switch(key) {
        case '1.1':
            text = "Parishudha kanyaa sthree mariyame, daiva vachanam ange thiruvudharathil manushyaavathaaram cheyyumennu Gabriel daivadoothan vazhi, daiva kalpanayaal angaye ariyichathine orthu angekkundaaya santhoshathe orthu dhyaanikkunna njangalude hrudayathilum ange sangrahichu kondirippaan krupa cheyyename."
            break
        case '1.2':
            text = "Parishudha daiva maathaave, ange thirukumaaran uyirthu naalpathaam divasam ethrayum mahima prathaapathodukoode swargaarohanam cheythathinaalundaaya mahimaye orthu dhyaanikkunna njangal, paraloka vaazchaye maathram aagrahichu moksham praapippan krupa cheyyename."
            break
        case '1.3':
            text = "Parishudha daivam aathaave, ange thirukumaaran swargarohannam cheythathinte pathaam naal oottushaalayil dhyaanichirunna thante melum thante shishyanmaarude melum parishudhaathmaavine yaathrayaakkiyathinaal undaaya mahimaye orthu dhyaanikkunna njangalum parishudhaathamaavinte prasaadhavarathaal daiva thirumanassu pole vyaaparippaan krupa cheyyename."
            break
        case '1.4':
            text = "Parishudha daiva maathaave, ange thirukumaaran uyirthezhunnaalli kurrekkaalam kazhinjappol angaye ihalokathil ninnum maalaakhamaaraal swargathileyku karetta pettathilundaaya santhoshathe orthu dhyaanikkunna njangalum angayude sahaayathaal mokshathil vannu cheruvaan krupa cheyyename."
            break
        case '1.5':
            text = "Parishudha daiva maathaave, angu paralokathil ezhunnalliya udana, ange thirukumaaran angaye thriloka raanjiyaayi mudi dharippichathinaal undaaya mahimaye orthu dhyaanikkunna njangalum swargathil santhatham daivathe sthuthichaanandippaan krupa cheyyename."
            break
        case '2.1':
            text = "Parishudha kanyaa sthree mariyame, daiva vachanam ange thiruvudharathil manushyaavathaaram cheyyumennu Gabriel daivadoothan vazhi, daiva kalpanayaal angaye ariyichathine orthu angekkundaaya santhoshathe orthu dhyaanikkunna njangalude hrudayathilum ange sangrahichu kondirippaan krupa cheyyename."
            break
        case '2.2':
            text = "Parishudha daiva maathaave, ange ilayammayaaya Eleesha punyavathiye angu chennu kandappol,aa punyavathiku sarveshwaran cheytha karunayekandu angekkundaaya santhoshathe orthu dhyaanikkunna njangal, laukika santhoshangal parithyajichu paraloka santhoshangale aagrahichu theduvaan krupa cheyanname."
            break
        case '2.3':
            text = "Parishudha daiva maathaave ange kanyaathwathinu antharam varaathe, angu daiva kumaarane prasavichathinaal angekkundaaya santhoshathe orthu dhyaanikkunna njangalude hrudayathilum angu njaanavidhamaayi pirrappan krupa cheyyename."
            break
        case '2.4':
            text = "Parishudha daiva maathaave ange thirukumaarane daivaalayathil kaazhcha vachchappol, mahaathmaakkal thanne sthuthikkunnathu kandu, angekkundaaya santhoshathe orthu dyaanikkunna njangal angekku yogyamaaya devaalayamaayirippaan krupa cheyyename."
            break
        case '2.5':
            text = "Parishudha daivamaathaave ange thirukumaarane panthrandaam vayassil kaanaathe poyappol, moonnaam divasam devaalayathil vachu tharkkichukondirikkayil angu thanne kandethiyathinaal undaaya santhoshathe orthu dyaanikkunna njangal, melil paapathaal vittu piriyaathirippaanum, vittu pirinjaaludane manasthaapathaal thanne kandethuvaanum krupa cheyyename."
            break
        case '3.1':
            text = "Parishudha daiva maathaave, ange thirukumaaran poonkaavanathil namaskarichu kondirikkumbol, raktham viyarthu ennathinmel dhyaanikunna njangal, njangallude paapangallinmel manasthapichu, paapaporruthi labhippaan krupa cheyyename."
            break
        case '3.2':
            text = "Parishudha daiva maathaave, ange thirukumaaran, kalthooninmel kettappettu, chammaattikaallaal adikkappettu ennathinmel dhyaanikkunna njangal, njangallude paapangallaal undaakunna kattina shikshakallil ninnum manasthaapathaalum nalla vyaapaarathaalum ozhinju maarruvaan krupa cheyyename."
            break
        case '3.3':
            text = "Parishudha daiva maathaave, ange thirukumaarante shirasil mullmudi dharippichu parihaasa raajaavaayittu thanne sthaapichathinmel dhyaanikkunna njangallude hrudayathilum, paapa mullukale manasthaapathaal pizhuthu kallayuvaan krupa cheyanname."
            break
        case '3.4':
            text = "Parishudha daivamaathaave ange thirukumaaran maranathinu vidhikkappettu bhaaramerriya kurishum chumannu kondu gaagulthaa malayilekku pokunnathinmel dhyaanikkunna njangal, njangalude dukhangalaakunna kurishin chumannu kondu thanne anugamikkuvaan krupa cheyyename."
            break
        case '3.5':
            text = "Parishudha daivamaathaave ange thirukumaaran, gaagulthaa malayil vachu, ange munpaake irumpaanikalaal kurishinmel tharakkappettu, ennathinmel dhyaanikkunna njangalude hrudayathilum, ange thiruppaadukalum vyakulangalum pathicharulename."
            break
        case '4.1':
            text = "Parishudha Daiva Maathave! Ange Thiru Kumaaran Jordan Nadhiyil Vechu Snaapaka Yohanaanil ninnum Maamodheesa Svekarichu sakala neethiyum poorthi aakiyathine pati dhyaanikuna njangal, maamodheesaayil labhicha dhaivika jeevanum prasadha varavum kaathu sookshikunathinum punya pravarthikaliloode avaye pushtipeduthi uthamma kristhiyaanikalaayi mathrika jeevitham nayikunathinum kripa cheyaname."
            break
        case '4.2':
            text = "Parishudha Daiva Maathave! Ange Thiru Kumaaran Caanaayile Kalyaanna Virunil Angayude Apeksha Prakaaram Vellam Veenjaaki aa kudumbathinte athyavishathil athbuthakaramaaya sahaayam nalkiyello. Ee Athbuthathe pati dhyaanikunna njangalude maanushika jeevithathe daivika chaithanyam kondu swargeeyamaaki theerkuvanulla daivaanugraham praapikuvan kripa cheyaname."
            break
        case '4.3':
            text = "Parishudha Daiva Maathave! Ange Thiru Kumaaranum njangalude karthaavumaaya Eesho Mishiha Manushya Makkale Manasantharathinaayi shanikkukayum suvishesha bagyangalum upmakalum aruli cheythukondum daiva rajiyathe pati prasangikkukayum cheythuvalo. Ee rakshaakara pravarthikale pati dhyaanikuna njangal paapa mochanamenha kudaashayiloode hridaya parivarthanam praapikuvanum daiva rajiyathinte suvishesham matrikha paramaya jeevithathiloode prakoshikuvanum kripa cheyaname."
            break
        case '4.4':
            text = "Parishudha Daiva Maathave! Ange Thiru Kumaaran Tabor malayil vechu roopaanthara pedukayum aviduthe daivika mahathvam shishyanmar darshikkukayum cheythuvalo. Ee divya rahasiyathinmel dhyaanikkuna njangal daivika vicharavum punya pravarthikalum vazhi jeevithathe vishudhee karikuvanum swargathilethi daiva mahathvam kandu nithyamaayi aanandhikuvanum kripa cheyaname."
            break
        case '4.5':
            text = "Parishudha Daiva Maathave! Ange Thiru Kumaaran anthya athaazha vellayil vishudha kurbana sthapichukondu thante shareera rakthangal njangalkku aadhyaathmika bakshanamaayi nalkiyello. Athbuthakaramaaya ee anantha snehathe kurichu dhyanikkunna njangal vishudha baliyiloodeyum divya karunya sveekaranathiloodeyum daivikaraayi roopantharam praapikuvan kripa cheyaname."
            break
        default:
            break
    }
    return text
}

function RosaryMalayalam() {

    return (<>
        <Descriptions title="Nanma Niranja Mariyame (Hail Mary)">
            <Descriptions.Item>
                Nanma Niranja Mariyamme, Swasthi.<br />
                Karthaavu Angayodu koode,<br />
                Sthreekalil Angu Anugrahikka pettaval aakunu.<br />
                Angayude Udharathin Bhalamaaya Eesho Anugrahakkipettavan aakunu.<br />
                Parishudha Mariyame, Thamburante Amme,<br />
                Papikalaaya Njangalkku Vendi,<br />
                Epozhum Njangalude Marana Samayathum Thamburanodu Apeshikaname.<br />
                Amen
            </Descriptions.Item>
        </Descriptions>

        <Descriptions title="Thri Sandhya Japam (The Angelus)">
            <Descriptions.Item>
                Karthaavinte Malaakha Parishudha Mariyathodu vachichu. Parisudhaatmaavaal Mariyam garbham dharichu. (Nanmaniranja Mariyame)<br />
                Ithaa Karthaavinte Daasi, Ninte Vachanam pole ennilaakatte. (Nanmaniranja Mariyame)<br />
                Vachanam maamsamaayi nammude idayil vasichu. (Nanmaniranja Mariyame)<br />
                <br />
                Eeso Mishihaayude vaagdaanangalku njangal yogyaraakuvaan, <br />
                Sarveshwarante Parisudha Daivamaathaave, njangalkuvendi Praarthikkaname.
            </Descriptions.Item>
        </Descriptions>
        <Descriptions title="Prarthikkaam">
            <Descriptions.Item>
                Sarveshwara ! Maalaakhayude sandeshathaal angayude puthranaaya eesho mishihaayude manushya avathaara vaartha arinjirikkunna njangal
                aviduthe peedanubhavavum kurishumaranavum mukhena uyirppinte mahima praapikkuvan anugraham cheyyaname yennu
                njangalude karthaveeshomishihaa vazhi angayodu njangal apekshikkunnu. Amen.<br /><br />
                Pithavinum Puthranum Parisudhatmavinum stuthi. <br />Aadiyileppole ippozhum eppozhum enneykkum. Amen. (3 times)
            </Descriptions.Item>
        </Descriptions>
        <Descriptions title="Kontha Namaskaaram (The Rosary)">
            <Descriptions.Item>
                Pithaavinteyum Puthranteyum Parishudhathmavinteyum naamathil. Amen
            </Descriptions.Item>
        </Descriptions>
        <Descriptions title="Aamukha praarthana">
            <Descriptions.Item>
                Alavillaatha sakala nanma swaroopiyaayirikkunna sarveshwaraa karthaave,
                neecha maanusharum nanhi heenarun paapikallumaayirikkunna adiyangal,
                aruthiyillaatha mahima prathaapathodu koodeyirikkunna ange sannidhiyil japam cheyyuvaan ayogyaraayirikkunnu.
                Enkilum ange anantha dayayinmel sharanappettukondu, parishudha maathaavinte sthuthikkaayittu ee anpathimoonnu mani japam cheyyuvaan agrahikkunna.
                Ee japam bhakthiyodu koode cheythu, palavichaaram koodaathe thikappaan, karthaave angu sahaayam cheyyanname.
            </Descriptions.Item>
        </Descriptions>
        <Descriptions title="Vishwaasa Pramaannam (The Apostles’ Creed)">
            <Descriptions.Item>
                Sarvashakthanaaya pithaavum, aakaashathinteyum bhoomiyudeyum Srushtaavumaaya  eka daivatthil njan vishwasikkunnu.<br />
                Aviduthe ekaputhranum, njangalude karthaavumaaya eesho mishihaayilum njan vishvasikkunnu.<br />
                Ee puthran parisudhaathmaavinaal garbhasthanaayi, kanyakaa mariyatthil ninnu pirannu.<br />
                Panthiyos Pilaathosinte kaalathu peedakal sahichu, kurishil tharakkappettu, marichu, adakkappettu, paathaalangalil irangi.<br />
                Marichavarude idayil ninnum moonnaam naal uyarthu.<br />
                Swargathilekku ezhunnalli. Sarvashakthiyulla Pithavinte valathu bhaagathu irikkunnu.<br />
                Avidunne jeevikkunnavareyum marichavareyum vidhikkuvaan varumennum njan viswasikkunnu.<br />
                Parishudhaathmaavilum njan vishwasikkunnu.<br />
                Vishudha katholikkaa sabhayilum, punyavaanmaarude aikkyathilum,<br />
                Paapangalude mochanatthilum,<br />
                Shareeratthinte uyirppilum,<br />
                Nithyamaaya jeevithatthilum njan viswasikkunnu. Aamen.
            </Descriptions.Item>
        </Descriptions>
        <Descriptions title="Swargasthanaaya (Our Father in Heaven)">
            <Descriptions.Item>
                Swargasthanaaya Njangalude Pithaave,<br />
                Angayude Naamam Poojitham Aakaname,<br />
                Angayude Rajyam Varaname,<br />
                Angayude Thirumanasu Swargathile pole Bhoomiyilum Aakaname.<br />
                Anannu Vendunha Aahaaram Innu Njangalkku Tharaname,<br />
                Njangalodu Thettu Cheyyunavarodu Njangal Shemichirikunathu Pole Njangalodum Shemikkaname,<br />
                Njangale Pralobanathil Ullpedutharuthe,<br />
                Thinmayil Ninnum Njangale Rakshikkaname.
            </Descriptions.Item>
        </Descriptions>
        <Descriptions title="">
            <Descriptions.Item>
                Pithaavaaya daivathinte makalaayirikkunna parishudha daivamaathaave, njangalil daiva viswaasamenna punyamundaayi phalam cheyyuvaanaayittu, ange thirukkumaaranodu apekshikkaname. (Nanma Niranja Mariyame)<br />
                Puthranaaya daivathinte maadhavaayirikkunna parishudha daivamaathaave, njangalil daiva sharanamenna punyamundaayi valaruvaanaayittu, ange thirukumaaranodu apekshikkaname. (Nanma Niranja Mariyame)<br />
                Parishuddhaathmaavaaya ettam priyappettavalaayirikkunna parishudha daivamaathaave, njangalil daiva snehamenna punyamundaayi varidhikkunnanthinu, ange thirukkumaaranodu apekshikkaname. (Nanma Niranja Mariyame)
                <br /><br />
                Pithavinum Puthranum Parisudhatmavinum stuthi. Aadiyileppole ippozhum eppozhum enneykkum. Amen
            </Descriptions.Item>
        </Descriptions>
        <Descriptions title="Rahasyangal (Mystery)">
            <Descriptions.Item>
                Sunday, Wednesday, Easter - Mahimayude Rahasyanagal <br />
                Monday, Saturday - Santhoshathinte Rahasyangal <br />
                Tuesday, Friday, Lenten Season - Dukhathinte Rahasyangal <br />
                Thurday - Prakaashathinte Rahasyangal
            </Descriptions.Item>
        </Descriptions>
        <Collapse accordion bordered={true}>
            <Panel header="Mahimayude Rahasyanagal (The Glorious Mysteries)" key="1">
                {rosary('1')}
            </Panel>
            <Panel header="Santhoshathinte Rahasyangal (The Joyful Mysteries in Malayalam)" key="2">
                {rosary('2')}
            </Panel>
            <Panel header="Dukhathinte Rahasyangal (The Sorrowful Mysteries) " key="3">
                {rosary('3')}
            </Panel>
            <Panel header="Prakaashathinte Rahasyangal (The Luminous Mysteries)" key="4">
                {rosary('4')}
            </Panel>
        </Collapse><br/>
        <Descriptions title="Fatima Prayer">
            <Descriptions.Item>
                Oh ente eeshoye njangalude paapangal kshamikkaname. Narakaangniyilninnu njangale rekshikkaname. Ella aathmakkaleyum prathyekichu ange sahaayam ettam kooduthal aavasyamullavareyum swargathileyku aanayikkaname!
            </Descriptions.Item>
        </Descriptions>
        <Descriptions title="Japamaala Samarpanam">
            <Descriptions.Item>
                Mukhya Dhoodhanaaya Vishudha Mikhaayele, Daiva dhoodhan maaraaya Vishudha Gabriele,
                Vishudda Rappaayele, Mahaathamaavaaya Vishuddha Yauseppe, Sleehanmaaraaya Vishuddha Pathrosse,
                Maar Paulose, Maar Yohannaane, njangalude pithaavaaya Mar Thommaa,
                njangal valiya paapikal aayirikkunnu enkilum, njangal japicha ee praarthana,
                ningallude keerthanangallodu koode onnaayi cherthu parishudha daiva maathaavinte thruppaadathinkal kaazcha vaykkuvaan ningallodu njangal praarthikunnu.
            </Descriptions.Item>
        </Descriptions>
        <Descriptions title="Daiva Maathavinte Luthiniya (Litany of the Blessed Virgin Mary)">
            <Descriptions.Item>
                Karthaave Anugrahikkanname / Karthaave Anugrahikkanname<br />
                Mishihaaye Anugrahikkanname / Mishihaaye Anugrahikkanname<br />
                Karthaave Anugrahikkanname / Karthaave Anugrahikkanname<br />
                Mishihaaye njangallude Praathana Kelkenname / Mishihaaye njangallude Praathana Kelkenname<br />
                Mishihaaye njangallude Praathana Kaikollenname / Mishihaaye njangallude Praathana Kaikollenname<br />
                Swargasthanaaya Pithaavaaya Daivame / Njangalle Anugrahikkanname<br />
                Bhooloka rakshakanaaya Puthranaaya Daivame / Njangalle Anugrahikkanname<br />
                Parshuddhaathmaavaaya Daivame / Njangalle Anugrahikkanname<br />
                Ekadaivamaaya parishuddha thrithwame / Njangalle Anugrahikkanname<br />
                Parishuddha Marriyame  / Njangalkku vendi apekshikanname<br />
                Thamburaante punnya jananee / Njangalkku vendi apekshikanname<br />
                Kanyakakalkku makudamaaya nirmmala kanyake / Njangalkku vendi apekshikanname<br />
                Mishihaayude maathaave / Njangalkku vendi apekshikanname<br />
                Daiva vara prasaadathinte Maathaave / Njangalkku vendi apekshikanname<br />
                Athyantha virakthiyulla Maathaave/ Njangalkku vendi apekshikanname<br />
                Karrayata kanyakayaayirikkunna Maathaave/ Njangalkku vendi apekshikanname<br />
                Kanyaavruthathinu bhangam varaatha Maathaave/ Njangalkku vendi apekshikanname<br />
                Snehathinu paathramaayirikkunna Maathaave/ Njangalkku vendi apekshikanname<br />
                Athbhuthathinu vishayamaayirikkunna Maathaave/ Njangalkku vendi apekshikanname<br />
                Salbuddhiyude Maathaave  / Njangalkku vendi apekshikanname<br />
                Srishtaavinte Maathaave/ Njangalkku vendi apekshikanname<br />
                Rakshakante Maathaave  / Njangalkku vendi apekshikanname<br />
                Viveka aishwaryamulla kanyake  / Njangalkku vendi apekshikanname<br />
                Vanakkathinu ettam yogyayaaya kanyake  / Njangalkku vendi apekshikanname<br />
                Sthuthikku yogyayaaya kanyake  / Njangalkku vendi apekshikanname<br />
                Vallabhamulla kanyake  / Njangalkku vendi apekshikanname<br />
                Kanivulla kanyake  / Njangalkku vendi apekshikanname<br />
                Vishwasthayaayirikkunna kanyake  / Njangalkku vendi apekshikanname<br />
                Neethiyude darppaname  / Njangalkku vendi apekshikanname<br />
                Bodha njaanathinte simhaasaname  / Njangalkku vendi apekshikanname<br />
                Njangallude thellivinte kaaraname  / Njangalkku vendi apekshikanname<br />
                Aathmanjaana pooritha paathrame  / Njangalkku vendi apekshikanname<br />
                Bahumaanathinte pathrame  / Njangalkku vendi apekshikanname<br />
                Athbhuthakaramaaya bhakthiyude paathrame  / Njangalkku vendi apekshikanname<br />
                Daiva rahasyam nirranjirikkunna rosaapushpame  / Njangalkku vendi apekshikanname<br />
                Daveedinte kottaye  / Njangalkku vendi apekshikanname<br />
                Nirmmala dantham kondulla kottaye  / Njangalkku vendi apekshikanname<br />
                Swarnnaalayame  / Njangalkku vendi apekshikanname<br />
                Vaagdaanathinte pettakame  / Njangalkku vendi apekshikanname<br />
                Aakaasha mokshathinte vaathile  / Njangalkku vendi apekshikanname<br />
                Ushakaalathinte nakshathrame  / Njangalkku vendi apekshikanname<br />
                Rogikallude aarogyame  / Njangalkku vendi apekshikanname<br />
                Paapikallude sankethame  / Njangalkku vendi apekshikanname<br />
                Vyaakulanmaarude aashwaasame  / Njangalkku vendi apekshikanname<br />
                Kristhyaanikallude sahaayame  / Njangalkku vendi apekshikanname<br />
                Maalaakhamaarude raajnee  / Njangalkku vendi apekshikanname<br />
                Baavaanmaarude raanjee  / Njangalkku vendi apekshikanname<br />
                Deerkha darshikallude raajnee  / Njangalkku vendi apekshikanname<br />
                Shleehanmaarude raajnee  / Njangalkku vendi apekshikanname<br />
                Veda saakshikallude raajnee  / Njangalkku vendi apekshikanname<br />
                Vandhakanmaarude raajnee  / Njangalkku vendi apekshikanname<br />
                Kanyakakallude raajnee  / Njangalkku vendi apekshikanname<br />
                Sakala punyavaanmaarydeyum raajnee  / Njangalkku vendi apekshikanname<br />
                Amalothbhavayaayirikkunna raajnee  / Njangalkku vendi apekshikanname<br />
                Swargaaropithayaaya raajnee  / Njangalkku vendi apekshikanname<br />
                Parishuddha japamaalayude raajnee  / Njangalkku vendi apekshikanname<br />
                Karmmala sabhayude alankaarammayirikkunna raajnee  / Njangalkku vendi apekshikanname<br />
                Samaadhaanathinte raajnee  / Njangalkku vendi apekshikanname<br />
                <br />
                Bhooloka paapangalle neekkunna daiva chemmariyaattinkuttee - Karthaave, njangallude paapam porrukenname<br />
                Bhooloka paapangalle neekkunna daiva chemmariyaattinkuttee - Karthaave, njangallude praarthana kelkenname<br />
                Bhooloka paapangalle neekkunna daiva chemmariyaattinkuttee - Karthaave, njangalle anugrahikkenname<br />
                <br />
                Sarveshawarante punya poornayaya maathaave! Ithaa, njangal ninnil abhayam thedunnu. Njangallude aavashya nerathu njangallude apekshakal upekshikkaruthe. Bhaagyavathiyum anugraheethayumaaya kanyakaa maathaave, sakala aapathukallil ninnum eppozhum njangalle kaathukollename. Amen.
                <br /><br />
                Eesho mishihaayude vaagdaanangalku njangal yogyaraakuvan,<br />
                Sarveswarante parushuddha daiva maathaave, njangalkuvendi apekshikkename.
            </Descriptions.Item>
        </Descriptions>
        <Descriptions title="Parishudha Raanji (Hail Holy Queen)">
            <Descriptions.Item>
                Parisudha ranjee, karunayude maathaave, swasthy! Njangalude jeevanum maadhuryavum sharanavume swasthy!
                <br />
                Hawayude puram thallappetta makkallaaya njangal angepakkal nilavilikkunnu. Kannuneerinte ee thaazhvarayil ninnu angeppakkal njangal neduveer pedunnu. Aakayaal njangalude madhyasthe, angayude karunayulla kannukal njangalude nere thirikkaname.
                <br />
                Njangalude ee pravaasathinu shesham angayude udarathinte anugraheetha bhalamaaya eeshoye njangalkku kaanichu tharanname. Karunayum vaathsalyavum maadhuryavum niranja kanayaka Mariyame, Amen!
            </Descriptions.Item>
        </Descriptions>
        <Descriptions title="Ethrayum Dayayulla Maathave (Memorare)">
            <Descriptions.Item>
                Ethrayum Dayayulla Maathaave! Ange sankethathil odivannu, ange sahaayam thedi, ange maathyastham apekshichavaril oruvane yenkkilum angu upekshichathaayi kettittilla, ennu angu orkanname.
                <br />
                Kanyakakallude raanjiyaaya kanyake, dayayulla maathaave, ee vishwaasathil dhairyappettu, ange thruppaadathinkal njan annayunu.
                <br />
                Neduveerppittu vilapichu kannuneer chinthi, paapiyaaya njan ange dayaadhikyathe kaathukondu, ange sannidhiyil nilkunnu. Avatharicha vachanathinte maathaave, ente apeksha upekshikaathe, dayaa poorvam kettarullename. Amen.
            </Descriptions.Item>
        </Descriptions>
        <br />
        <p>Reference: <i>https://lordcalls.com/mother-mary-prayers/the-mysteries-in-malayalam</i></p>
        <br /><br /><br />
    </>)
}
export default RosaryMalayalam