import React from "react";
import { Descriptions } from 'antd';

function Penance() {
    return (
        <>
            <Descriptions title="Psalms 51">
                <Descriptions.Item>
                    (For the choirmaster of David when the prophet Nathan had come to him because he had gone to Bathsheba)<br/><br/>
                    1 Have mercy on me, O God, in your faithful love, in your great tenderness wipe away my offences;<br />
                    2 wash me clean from my guilt, purify me from my sin.<br />
                    3 For I am well aware of my offences, my son is constantly in mind.<br />
                    4 Against you, you alone, I have sinned, I have done what you see to be wrong, that you may show your saving justice when you pass sentence, and your victory may appear when you give judgement<br />
                    5 remember, I was born guilty, a sinner from the moment of conception.<br />
                    6 But you delight in sincerity of heart, and in secret you teach me wisdom.<br />
                    7 Purify me with hyssop till I am clean, wash me till I am whiter than snow.<br />
                    8 Let me hear the sound of joy and gladness and the bones you have crushed will dance.<br />
                    9 Turn away your face from my sins, and wipe away my guilt.<br />
                    10 God, create in me a clean heart, renew within me a resolute spirit,<br />
                    11 do not thrust me away from your presenece, do not take away from me your spirit of holiness.<br />
                    12 Give me back the joy of your salvation, sustain in me a generous spirit.<br />
                    13 I shall teach the wicked your paths and sinners will return to you.<br />
                    14 Deliver me from bloodshed, God, God of my salvation, and my tongue will acclaim your saving justice.<br />
                    15 Lord, open my lips, and my mouth will speak out your praise.<br />
                    16 Sacrifice gives you no pleasure, burnt offering you do not desire.<br />
                    17 Sacrifice to God is a broken spirit, a broken, contribute heart you never scorn.<br />
                    18 In your graciousness do good to Zion, rebuild the walls of Jerusalem.<br />
                    19 Then you will delight in upright sacrifices, - burnt offerings and whole oblations - and young bulls will be offered on your altar.
                </Descriptions.Item>
            </Descriptions>
            <br />
            <br />
        </>
    )
}

export default Penance;