import React from "react";
import { Badge, Card, Row, Col } from "antd";
import YouTube from "../Helper/YouTube";

function HolyMass(){
    return (
        <>
        <Row>
            <Col span={12}>
            <Badge.Ribbon text="Happy" color="volcano">
                <Card title="Lation Catholic - English" size="small">
                    <YouTube embedId='https://www.youtube.com/embed/UwWWVr3WtR8' />
                </Card>
            </Badge.Ribbon>
            <Badge.Ribbon text="Peace">
                <Card title="Syro Malabar - Malayalam" size="small">
                    <YouTube embedId='https://www.youtube.com/embed/JkAMyXbf2fU' />
                </Card>
            </Badge.Ribbon> 
            </Col>
            <Col span={12}>
            <Badge.Ribbon text="Prayer" color="cyan">
                <Card title="Latin Catholic - Malayalam" size="small">
                    <YouTube embedId='https://www.youtube.com/embed/AjQDZo_6A88' />
                </Card>
            </Badge.Ribbon>
            <Badge.Ribbon text="Trust" color="magenta">
                <Card title="Malankara Catholic - Holy Qurbana" size="small">
                <YouTube embedId='https://www.youtube.com/embed/PTrhlp5yIJ8' />
                </Card>
            </Badge.Ribbon>
            </Col>
        </Row>
        <br/><br/>
        </>
    )
}

export default HolyMass;
/*
Offer Holy Mass with intentions (
    (1) Souls in purgatory - iranthu pona aathmaakal, yaarum ninaiyaatha aanmakal, kudumbathil irathu pona aanmakal
    (2) Penance for sins ()
)
*/