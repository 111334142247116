import React from "react"
import { Descriptions, Collapse, Tabs } from 'antd'
import { sign } from '../Prayers/Novena'

const { Panel } = Collapse

const rosary = key => {
    const items = [
        {label: 'First Mystery', key: 1, children: key === '1' ? mystery('1.1') : key === '2' ? mystery('2.1') : key === '3' ? mystery('3.1') : key === '4' ? mystery('4.1') : <></>},
        {label: 'Second Mystery', key: 2, children: key === '1' ? mystery('1.2') : key === '2' ? mystery('2.2') : key === '3' ? mystery('3.2') : key === '4' ? mystery('4.2') : <></>},
        {label: 'Third Mystery', key: 3, children: key === '1' ? mystery('1.3') : key === '2' ? mystery('2.3') : key === '3' ? mystery('3.3') : key === '4' ? mystery('4.3') : <></>},
        {label: 'Fourth Mystery', key: 4, children: key === '1' ? mystery('1.4') : key === '2' ? mystery('2.4') : key === '3' ? mystery('3.4') : key === '4' ? mystery('4.4') : <></>},
        {label: 'Fifth Mystery', key: 5, children: key === '1' ? mystery('1.5') : key === '2' ? mystery('2.5') : key === '3' ? mystery('3.5') : key === '4' ? mystery('4.5') : <></>}
    ]
    return (
        <>
        <Tabs
            defaultActiveKey="1"
            type="card"
            size='small'
            items={items} /><br></br>
            {mysteryPrayer()}
        </>
    )
}

const mysteryPrayer = () => {
    return (
        <><p><i>Our Father (1 time)</i></p>
            <p><i>Hail Mary (10 times)</i></p>
            <p><i>Fatima Prayer</i></p></>
    )
}

const mystery = key => {
    let topic = ''
    let text = ''
    let spiritualFruit = 'Spiritual Fruit: '
    switch (key) {
        case '1.1':
            topic = 'THE RESURRECTION'
            text = `The body of Jesus is placed in the tomb on the evening of Good Friday. 
            His soul descends into the realm of the dead to announce to the Just the tidings of their redemption. 
            Fearing the body of Jesus will be taken, the chief priests place guards at the tomb. 
            On the third day Jesus rises from the dead, glorious and immortal. 
            The earth quakes as the angel rolls back the stone, the guards flee in terror. 
            The holy women coming to anoint the body of Jesus are amazed and frightened to find the tomb open. 
            An angel calms their fears: "He is not here. He has risen as He said." 
            Jesus appears to Mary Magdalen and Peter and two disciples on the way to Emmaus. 
            That evening He appears to the apostles behind locked doors: "Peace be unto you ... do not be afraid." 
            Jesus breathes on them and gives them the power to forgive sin." `
            spiritualFruit += ' Faith'
            break
        case '1.2':
            topic = ''
            text = ``
            spiritualFruit = ''
            break
        case '1.3':
            topic = ''
            text = ``
            spiritualFruit = ''
            break
        case '1.4':
            topic = ''
            text = ``
            spiritualFruit = ''
            break
        case '1.5':
            topic = ''
            text = ``
            spiritualFruit = ''
            break
        case '2.1':
            topic = ''
            text = ``
            spiritualFruit = ''
            break
        case '2.2':
            topic = ''
            text = ``
            spiritualFruit = ''
            break
        case '2.3':
            topic = ''
            text = ``
            spiritualFruit = ''
            break
        case '2.4':
            topic = ''
            text = ``
            spiritualFruit = ''
            break
        case '2.5':
            topic = ''
            text = ``
            spiritualFruit = ''
            break
        case '3.1':
            topic = ''
            text = ``
            spiritualFruit = ''
            break
        case '3.2':
            topic = ''
            text = ``
            spiritualFruit = ''
            break
        case '3.3':
            topic = ''
            text = ``
            spiritualFruit = ''
            break
        case '3.4':
            topic = ''
            text = ``
            spiritualFruit = ''
            break
        case '3.5':
            topic = ''
            text = ``
            spiritualFruit = ''
            break
        case '4.1':
            topic = ''
            text = ``
            spiritualFruit = ''
            break
        case '4.2':
            topic = ''
            text = ``
            spiritualFruit = ''
            break
        case '4.3':
            topic = ''
            text = ``
            spiritualFruit = ''
            break
        case '4.4':
            topic = ''
            text = ``
            spiritualFruit = ''
            break
        case '4.5':
            topic = ''
            text = ``
            spiritualFruit = ''
            break
        default:
            break
    }
    return <><p><i>{topic}</i><br/>{text}<br/><i>{spiritualFruit}</i></p></>
}

function RosaryEnglish() {
    return (<>
        <Descriptions title="The Hail Mary">
            <Descriptions.Item>
            Hail Mary, full of grace. <br/>
            The Lord is with thee. <br/>
            Blessed art thou amongst women, And blessed is the fruit of thy womb, Jesus. <br/><br/>
            Holy Mary, Mother of God, Pray for us sinners, Now and at the hour of our death. <br/>
            Amen.
            </Descriptions.Item>
        </Descriptions>

        <Descriptions title="The Angelus">
            <Descriptions.Item>
                
            </Descriptions.Item>
        </Descriptions>
        <Descriptions title="Let us Pray">
            <Descriptions.Item>
                Sarveshwara ! Maalaakhayude sandeshathaal angayude puthranaaya eesho mishihaayude manushya avathaara vaartha arinjirikkunna njangal
                aviduthe peedanubhavavum kurishumaranavum mukhena uyirppinte mahima praapikkuvan anugraham cheyyaname yennu
                njangalude karthaveeshomishihaa vazhi angayodu njangal apekshikkunnu. Amen.<br /><br />
                Pithavinum Puthranum Parisudhatmavinum stuthi. <br />Aadiyileppole ippozhum eppozhum enneykkum. Amen. (3 times)
            </Descriptions.Item>
        </Descriptions>
        <Descriptions title="The Rosary">
            <Descriptions.Item>
                Pithaavinteyum Puthranteyum Parishudhathmavinteyum naamathil. Amen
            </Descriptions.Item>
        </Descriptions>
        <Descriptions title="Aamukha praarthana">
            <Descriptions.Item>
                Alavillaatha sakala nanma swaroopiyaayirikkunna sarveshwaraa karthaave,
                neecha maanusharum nanhi heenarun paapikallumaayirikkunna adiyangal,
                aruthiyillaatha mahima prathaapathodu koodeyirikkunna ange sannidhiyil japam cheyyuvaan ayogyaraayirikkunnu.
                Enkilum ange anantha dayayinmel sharanappettukondu, parishudha maathaavinte sthuthikkaayittu ee anpathimoonnu mani japam cheyyuvaan agrahikkunna.
                Ee japam bhakthiyodu koode cheythu, palavichaaram koodaathe thikappaan, karthaave angu sahaayam cheyyanname.
            </Descriptions.Item>
        </Descriptions>
        <Descriptions title="The Apostles’ Creed">
            <Descriptions.Item>
                
            </Descriptions.Item>
        </Descriptions>
        <Descriptions title="Our Father in Heaven">
            <Descriptions.Item>
            Our Father, who art in heaven hallowed be thy name; <br/>
            thy kingdom come; thy will be done on earth as it is in heaven. <br/>
            Give us this day our daily bread and forgive us our trespasses as we forgive those who trespass against us; <br/>
            and lead us not into temptation, but deliver us from evil. Amen.
            </Descriptions.Item>
        </Descriptions>
        <Descriptions title="">
            <Descriptions.Item>
                . (Hail Mary)<br />
                . (Hail Mary)<br />
                . (Hail Mary)<br />
                <br /><br />
                {sign}
            </Descriptions.Item>
        </Descriptions>
        <Descriptions title="Mystery">
            <Descriptions.Item>
                Sunday, Wednesday, Easter - The Glorious Mysteries<br />
                Monday, Saturday - The Joyful Mysteries<br />
                Tuesday, Friday, Lenten Season - The Sorrowful Mysteries<br />
                Thurday - The Luminous Mysteries
            </Descriptions.Item>
        </Descriptions>
        <Collapse accordion bordered={true}>
            <Panel header="The Glorious Mysteries" key="1">
                {rosary('1')}
            </Panel>
            <Panel header="The Joyful Mysteries" key="2">
                {rosary('2')}
            </Panel>
            <Panel header="The Sorrowful Mysteries" key="3">
                {rosary('3')}
            </Panel>
            <Panel header="The Luminous Mysteries" key="4">
                {rosary('4')}
            </Panel>
        </Collapse><br/>
        <Descriptions title="Fatima Prayer">
            <Descriptions.Item>
                
            </Descriptions.Item>
        </Descriptions>
        <Descriptions title="Japamaala Samarpanam">
            <Descriptions.Item>
                
            </Descriptions.Item>
        </Descriptions>
        <Descriptions title="Litany of the Blessed Virgin Mary">
            <Descriptions.Item>
                
            </Descriptions.Item>
        </Descriptions>
        <Descriptions title="Hail Holy Queen">
            <Descriptions.Item>
                
            </Descriptions.Item>
        </Descriptions>
        <Descriptions title="Memorare">
            <Descriptions.Item>
                
            </Descriptions.Item>
        </Descriptions>
        <br /><br /><br />
    </>)
}
export default RosaryEnglish