import React from "react";
import { Descriptions } from 'antd';

function Marriage() {
    return (<>
        <Descriptions title="I Corinthians 7:2">
            <Descriptions.Item>
                Yet to avoid immorality every man should have his own wife and every woman her own husband.
            </Descriptions.Item>
        </Descriptions>
        <Descriptions title="Isaiah 62:4">
            <Descriptions.Item>
                No more will you be known as 'Forsaken' or your country be known as 'Desolation'; instead, you will be called <br />
                'My Delight is in her'<br />
                and your country 'The Wedded';<br />
                for Yahweh will take delight in you and your country will have its wedding.
            </Descriptions.Item>
        </Descriptions>
        <Descriptions title="Isaiah 34:16">
            <Descriptions.Item>
                Search in Yahweh's book, and read, not one of these will be missing, not one of them lacking a mate; <br />
                for thus his mouth has ordained it and his spirit has brought them together.
            </Descriptions.Item>
        </Descriptions>
        <Descriptions title="Genesis 2:18">
            <Descriptions.Item>
                Yahweh God said, 'It is not right that the man should be alone. I shall make him a helper.'
            </Descriptions.Item>
        </Descriptions>
        <Descriptions title="Tobit 8:6">
            <Descriptions.Item>
                You it was Who created Adam, You who created Eve his wife to be his help and support;
                and from these two the human race was born. You it was who said, <br />
                'It is not right that the man should be alone; let us make him a helper like him.'
            </Descriptions.Item>
        </Descriptions>
        <Descriptions title="Isaiah 43:6">
            <Descriptions.Item>
                To the north I shall say, 'Give them up!' and to the south, 'Do not hold them back!'<br />
                Bring back my sons from far away, and my daughters from the remotest part of the earth.
            </Descriptions.Item>
        </Descriptions>
        <Descriptions title="Isaiah 60:20">
            <Descriptions.Item>
                Your sun will set no more nor will your moon wane, for Yahweh will be your everlasting light and your days of mourning will be over.
            </Descriptions.Item>
        </Descriptions>
        <Descriptions title="Isaiah 65:24">
            <Descriptions.Item>
                Thus, before they call I shall answer, before they stop speaking I shall have heard.
            </Descriptions.Item>
        </Descriptions>
        <Descriptions title="Hebrews 13:4">
            <Descriptions.Item>
                Marriage must be honoured by all, and marriages must be kept undefiled, because the sexually immoral and the adulterers will come under God's judgement.
            </Descriptions.Item>
        </Descriptions>
        <Descriptions title="Tobit 6:18">
            <Descriptions.Item>
                The demon will smell it and flee and, and there is no danger that he will ever be found again. Then, before you sleep together, first stand up, both of you, and pray. <br />
                Ask the Lord of heaven to grant you his grace and and protection. Do not be afraid; she was destined for you from the beginning, and you are the one to save her. <br />
                She will follow you, and I pledge my word she will give you children who will be like brothers to you. Do not worry.'<br />
                And when Tobias heard Raphael say this, when he understood that Sarah was his sister, a kinswoman of his father's family, he fell so deeply in love with her that he could no longer call his heart his own.
            </Descriptions.Item>
        </Descriptions>
        <Descriptions title="Jeremiah 29:11">
            <Descriptions.Item>
                Yes, I know what plans I have in mind for you, Yahweh declares, plans for peace, not for disaster, to give you a future and a hope.
            </Descriptions.Item>
        </Descriptions>
        <br />
        <br />
        <br />
    </>);
}
export default Marriage;

/*Trust in God's providence

Jeremiah 17:5-8
*/