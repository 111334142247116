import React from "react";
import { Descriptions } from 'antd';

function Faith() {
    return (
        <>
            <Descriptions title="Isaiah 25:9">
                <Descriptions.Item>
                    And on that day, it will be said,<br />
                    'Look, this is our God, in him we put our hope that he should save us,<br />
                    this is Yahweh, we put our hope in him.<br />
                    Let us exult and rejoice since he has saved us.'<br />
                </Descriptions.Item>
            </Descriptions>
            <Descriptions title="Isaiah 26:4">
                <Descriptions.Item>
                    Trust in Yahweh forever, for Yahweh is a rock forever.
                </Descriptions.Item>
            </Descriptions>
        </>
    )
}

export default Faith;