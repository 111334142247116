import React from 'react';
import Landing from '../Landing/Landing';
import './App.css';

function App() {
  return(
    <div className='App'><Landing/></div>
  )
}

export default App;
