import React from "react";
import { Descriptions } from 'antd';

function Charity() {
    return (
        <>
            <Descriptions title="Tobit 1:16-17 ">
                <Descriptions.Item>
                    16 In the days of Shalmaneser I had often given alms to the people of my race;<br />
                    17 I gave my bread to the hungry and clothes to those who lacked them; 
                    and I buried, when I saw them, the bodies of my country-folk thrown over the walls of Nineveh.
                </Descriptions.Item>
            </Descriptions>
        </>
    )
}

export default Charity;