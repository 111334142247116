import React from "react"
import { sign, intention, amen } from "../Novena"

export const JudeItems = [
    { label: 'Day 1', key: 1, children: <Jude props={[{key:1}]} /> },
    { label: 'Day 2', key: 2, children: <Jude props={[{key:2}]} /> },
    { label: 'Day 3', key: 3, children: <Jude props={[{key:3}]} /> },
    { label: 'Day 4', key: 4, children: <Jude props={[{key:4}]} /> },
    { label: 'Day 5', key: 5, children: <Jude props={[{key:5}]} /> },
    { label: 'Day 6', key: 6, children: <Jude props={[{key:6}]} /> },
    { label: 'Day 7', key: 7, children: <Jude props={[{key:7}]} /> },
    { label: 'Day 8', key: 8, children: <Jude props={[{key:8}]} /> },
    { label: 'Day 9', key: 9, children: <Jude props={[{key:9}]} /> }
]

const var1 = 'Most holy St. Jude – apostle, martyr and friend of Jesus, today I ask that you pray for me and my intentions!'
const var2 = 'You are the patron of the impossible. Pray for me and my intentions! O St. Jude, pray that God’s grace and mercy will cover my intentions. Pray for the impossible if it is God’s will.'
const var3 = 'Pray that I may have the grace to accept God’s holy will even if it is painful and difficult for me.'
const var4 = 'O St. Jude, pray for me that I may grow in faith, hope and love and in the grace of Jesus Christ. Pray for these intentions, but most of all pray that I may join you in heaven with God for all eternity.'

const word1 = 'St. Jude, you loved our Lord, help me to love Him more.'
const word2 = 'St. Jude, pray that I may have your zeal to preach the Gospel.'
const word3 = 'St. Jude, you labored for years, pray that I may have patience in my struggles.'
const word4 = 'St. Jude, you are known for answering lost causes, pray for my most impossible needs.'
const word5 = 'St. Jude, you sacrificed for the Kingdom of God, pray that I may sacrifice like you.'
const word6 = 'St. Jude, pray that I may know God’s will in my life.'
const word7 = 'St. Jude, pray that I may put my trust in God that He knows my needs even better than I do and that He provides.'
const word8 = 'St. Jude, pray for me that I will not lose hope.'
const word9 = 'St. Jude, pray for me that I will not lose faith.'

function setWord(key) {
    switch (key) {
        case 1:
            return word1;
        case 2:
            return word2;
        case 3:
            return word3;
        case 4:
            return word4;
        case 5:
            return word5;
        case 6:
            return word6;
        case 7:
            return word7;
        case 8:
            return word8;
        case 9:
            return word9;
        default:
            return;
    }
}

function Jude(data) {
    return (
        <p>
            {sign}<br /><br />

            {var1}<br />

            <br /><i>{intention}</i><br /><br />

            {var2}<br />
            {var3}<br />

            {data!=null&&data.props!=null&& data.props[0]!=null && data.props[0].key!=null ? <> <br/>{setWord(data.props[0].key)}<br /><br/></> : <br/>} 

            {var4}<br />

            {amen}<br /><br />
            {sign}<br />
        </p>
    )
}