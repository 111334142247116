import React from "react";
import YouTube from "../Helper/YouTube";
import {List} from "antd";

function AudioBible() {
    return (
        <List.Item 
        key="Inspired From Shalom World"
        extra={<YouTube embedId='https://www.youtube.com/embed/UL9dFDpMyio' />}>
        </List.Item>
    )
}

export default AudioBible;