import React from "react";
import { Descriptions } from 'antd';

function Deliverance() {
    return (<>
        <Descriptions title="Mark 1:23-36">
            <Descriptions.Item>
                23 And at once in their synagoue there was a man with an unclean spirit, and he shouted, <br />
                24 'What do you want with us, Jesus of Nazareth? Have you come to destroy us? I know who you are: the Holy One of God.'<br />
                25 But Jesus rebuked it saying, 'Be quiet! Come out of him!'<br />
                26 And the unclean spirit threw the man into convulsions and with a loud cry went out of him.
            </Descriptions.Item>
        </Descriptions>
        <Descriptions title="Mark 5:1-13">
            <Descriptions.Item>
                1 They reached the territory of the Gerasenes on the other side of the lake, <br />
                2 and when he disembarked, a man with an unclean spirit at once came out from the tombs towards him.<br />
                3 The man lived in the tombs and no one could secure him any more, even with a chain, <br />
                4 because he had often been secured with fetters and chains but had snapped the chains and broken the fetters, and no one had the strength to control him. <br />
                5 All night and all day, among the tombs and in the mountains, he would howl and gash himself with stones. <br />
                6 Catching sight of Jesus from a distance, he ran up and fell at His feet <br />
                7 and shouted at the top of his voice, 'What do you want with me, Jesus, son of the Most High God? In God's name do not torture me! <br />
                8 For Jesus had been saying to him, 'Come out of the man, unclean spirit.'<br />
                9 Then He asked, 'What is your name?' He answered, 'My name is Legion, for there are many of us.'<br />
                10 And he begged Him earnestly not to send them out of the district. <br />
                11 Now on the mountainside there was a great herd of pigs feeding, <br />
                12 and the unclean spirits begged him, 'Send us to the pigs, let us go into them.'<br />
                13 So he gave them leave. With that the unclean spirits came out and went into the pigs, and the herd of about two thousand pigs charged down the cliff into the lake, and there they were drowned.
            </Descriptions.Item>
        </Descriptions>
        <Descriptions title="Mark 9:14-27">
            <Descriptions.Item>
                14 As they were rejoining the disciples they saw a large croud around them and some scribes arguing with them.<br />
                15 At once, when they saw Him, the whole crowd were struck with amazement and ran to greet Him.<br />
                16 And he asked them, 'What are you arguing about with them?'<br />
                17 A man answered Him from the crowd, 'Master, I have brought my son to you; and there is a spirit of dumbness in him,<br />
                18 and when it takes hold of him it throws him to the ground, and he foams at the mouth and grinds his teeth and goes rigid. And I asked Your disciples to drive it out and they were unable to.'<br />
                19 In reply He said to them, 'Faihthless generation, how much longer must I be among you? How much longer must I put up with you? Bring him to me.'<br />
                20 They brought the boy to Him, and at once the spirit of dumbness threw the boy into violet convulsions, and he fell to the ground and lay writhing there, foaming at the mouth.<br />
                21 Jesus asked the father, 'How long has this been happening to him?' 'From childhood,' he said, <br />
                22 'and it has often thrown him into fire and water, in order to destroy him. <br />
                23 But if You can do anything, have pity on us and help us'<br />
                24 'If you can?' retorted Jesus. 'Everything is possible for one who has faith.' At once the father of the boy cried out, 'I have faith. Help my lack of faith!'<br />
                25 And when Jesus saw that a crowd was gathering, he rebuked the unclean spirit. 'Deaf and dumb spirit,' he said, 'I command you: come out of him and never enter him again.'<br />
                26 Then it threw the boy into violet convulsions and came out shouting, and the boy lay there so like a corpse that most of them  said, 'He is dead.'<br />
                27 But Jesus took him by the hand and helped him up, and he was able to stand. <br />
            </Descriptions.Item>
        </Descriptions>
        <br />
        <br />
        <br />
    </>);
}
export default Deliverance;