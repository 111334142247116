import React from "react";
import { Descriptions } from 'antd';

function Thanksgiving() {
    return (
        <>
            <Descriptions title="Sirach 51:1-10">
                <Descriptions.Item>
                    1 I shall give thanks to you, Lord and King, and praise you, God my Saviour, I give thanks to your name;<br />
                    2 for you have been my guard and support and redeemed my body from destruction, from the snare of the lying tongue, from lips that fabricate falsehood; in the presence of my assailants, you were on my side; you have been my support, you have redeemed me,<br />
                    3 true to your abounding kindness -- and the greatness of your name -- you liberated me from the fangs of those seeking to devour me, from the clutches of those seeking my life, from the many ordeals which I have endured,<br />
                    4 from the stifling heat which hemmed me in, from the heart of a fire which I had not kindled,<br />
                    5 from deep in the belly of Sheol,<br />
                    6 treacherous denunciations to the king. My soul has been close to death, my life had gone down to the brink of Sheol.<br />
                    7 I was completely surrounded, there was no one to help me; I looked for someone to help me, there was no one.<br />
                    8 Then I remembered your mercy, Lord, and your deeds from earliest times, how you deliver those who wait for you patiently, and save them from the clutches of their enemies.<br />
                    9 And I sent up my plea from the earth, I begged to be delivered from death.<br />
                    10 I called on the Lord, the father of my Lord, 'Do not desert me in the days of ordeal, in the days of the proud, when we are helpless. I shall praise your name unceasingly and gratefully sing its praises.'<br />
                    11 And my plea was heard, for you saved me from destruction, you delivered me from that time of evil.<br />
                    12 And therefore I shall thank you and praise you, and bless the name of the Lord.<br />
                </Descriptions.Item>
            </Descriptions>
        </>
    )
}

export default Thanksgiving;