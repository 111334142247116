import React from "react";
import { Row, Col, Badge, Card } from 'antd';
import YouTube from "../Helper/YouTube";

function Retreat() {
    return (
        <>
            <Row>
                <Col span={12}>
                    <Badge.Ribbon text="Healing" color="volcano">
                        <Card title="1st Saturday Bible Convention" size="small">
                            <YouTube embedId='https://www.youtube.com/embed/YNaumxlp5kQ' />
                        </Card>
                    </Badge.Ribbon>
                </Col>
                <Col span={12}>
                    <Badge.Ribbon text="Truth" color="volcano">
                        <Card title="The Resurrection Episode" size="small">
                            <YouTube embedId='https://www.youtube.com/embed/2SHk2Cc3EmU' />
                        </Card>
                    </Badge.Ribbon>
                </Col>
            </Row>
        </>
    )
}

export default Retreat;