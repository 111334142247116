import React from "react"
import { sign, intention, amen } from "../Novena"

export const SacredHeartOfJesusItems = [
    { label: 'Day 1', key: 1, children: <SacredHeartOfJesus props={[{key:1}]} /> },
    { label: 'Day 2', key: 2, children: <SacredHeartOfJesus props={[{key:2}]} /> },
    { label: 'Day 3', key: 3, children: <SacredHeartOfJesus props={[{key:3}]} /> },
    { label: 'Day 4', key: 4, children: <SacredHeartOfJesus props={[{key:4}]} /> },
    { label: 'Day 5', key: 5, children: <SacredHeartOfJesus props={[{key:5}]} /> },
    { label: 'Day 6', key: 6, children: <SacredHeartOfJesus props={[{key:6}]} /> },
    { label: 'Day 7', key: 7, children: <SacredHeartOfJesus props={[{key:7}]} /> },
    { label: 'Day 8', key: 8, children: <SacredHeartOfJesus props={[{key:8}]} /> },
    { label: 'Day 9', key: 9, children: <SacredHeartOfJesus props={[{key:9}]} /> }
]

const var1 = ''
const var2 = ''
const var3 = ''
const var4 = ''

const word1 = ''
const word2 = ''
const word3 = ''
const word4 = ''
const word5 = ''
const word6 = ''
const word7 = ''
const word8 = ''
const word9 = ''

function setWord(key) {
    switch (key) {
        case 1:
            return word1;
        case 2:
            return word2;
        case 3:
            return word3;
        case 4:
            return word4;
        case 5:
            return word5;
        case 6:
            return word6;
        case 7:
            return word7;
        case 8:
            return word8;
        case 9:
            return word9;
        default:
            return;
    }
}

function SacredHeartOfJesus(data) {
    return (
        <p>
            {sign}<br /><br />

            {var1}<br />

            <br /><i>{intention}</i><br /><br />

            {var2}<br />
            {var3}<br />

            {data!=null&&data.props!=null&& data.props[0]!=null && data.props[0].key!=null ? <> <br/>{setWord(data.props[0].key)}<br /><br/></> : <br/>} 

            {var4}<br />

            {amen}<br /><br />
            {sign}<br />
        </p>
    )
}