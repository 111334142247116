import React from "react";
import "./youtube.css";

function YouTube(key) {

    return (
        <>
            <div className="video-responsive">
            <iframe src={key.embedId} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </>
    )
}

export default YouTube;