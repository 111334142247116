import React from "react";
import {Descriptions} from 'antd';

function Providence() {
    return (
        <>
         <Descriptions title="Jeremiah ">
            <Descriptions.Item>

            </Descriptions.Item>
        </Descriptions>
        </>
    )
}

export default Providence;