import React from "react";
import { Descriptions } from 'antd';

function Promises() {
    return (
        <>

            <Descriptions title="Isaiah 37:35">
                <Descriptions.Item>
                    I shall protect this city and save it for my sake and my servant David's sake.
                </Descriptions.Item>
            </Descriptions>
            <Descriptions title="Sirach 32:24">
                <Descriptions.Item>
                    Anyone who trusts in the Law obeys its precepts, no one who has confidence in the Lord will come to harm.
                </Descriptions.Item>
            </Descriptions>
            <Descriptions title="Sirach 33:1">
                <Descriptions.Item>
                    No evil will befall one who fears the Lord, such a one will be rescued even in the ordeal.
                </Descriptions.Item>
            </Descriptions>
            <Descriptions title="Sirach 34:13">
                <Descriptions.Item>
                    the spirit of those who fear the Lord can survive, for their hope is in someone with power to save them.
                </Descriptions.Item>
            </Descriptions>
            <Descriptions title="Isaiah 25:8">
                <Descriptions.Item>
                    he has destroyed death forever.<br/>
                    Lord Yahweh has wiped away the tears from every cheek;<br/>
                    he has taken his people's shame away everywhere on earth,<br/>
                    for Yahweh has spoken.
                </Descriptions.Item>
            </Descriptions>
        </>
    )
}

export default Promises;