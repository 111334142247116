import React, { useState, useEffect } from "react";
import { Alert, Badge, Button, Calendar, Collapse, Popover, Row, Col, Spin, Tabs } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons'
import dayjs from 'dayjs';
import { useSelector } from "react-redux";
import { loadingIcon } from '../../Landing/LandingHelper'

const { Panel } = Collapse;

function Saints() {
    const [value, setValue] = useState(() => dayjs(new Date()));
    const [selectedValue, setSelectedValue] = useState(() => dayjs(new Date()));
    const saints = useSelector(state => state.landing.saints)
    const saintsStatus = useSelector(state => state.landing.status)
    const [showSaint, setShowSaint] = useState(false)

    useEffect(() => {

    }, [])

    const onSelect = (newValue) => {
        setValue(newValue);
        setSelectedValue(newValue);
    };

    const onPanelChange = (newValue) => {
        setValue(newValue);
    };

    const dateCellRender = value => {
        let list = (saints[value.month()] != null && saints[value.month()].length > 0) ? saints[value.month()][value.date() - 1] : []
        let content = list.length !== 0 ? list.map(i => (<><Badge color="midnightblue" text={i.name} /><br /></>)) : <></>
        return (
            list.length !== 0 ? <Popover placement="top" content={content}><Button>{list.length} Saints</Button></Popover> : <></>
        )
    }

    const monthCellRender = value => {
        return (<></>)
    }
    const saintsDescription = index => {
        return (
            <Collapse accordion expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
                {saints[value.month()] !== null && saints[value.month()][index] !== null && saints[value.month()][index].length !== 0 ? saints[value.month()][index].map(e => { return (<Panel key={e.id} header={e.name}>{e.description}</Panel>) }) : <p>No Data</p>}
            </Collapse>
        )
    }

    const items = saints[value.month()] !== null && saints[value.month()].length > 0 ? saints[value.month()].map((e, i) => {
        const id = String(i + 1);
        
        return {
            label: `${value.format('MMM')} ${id}`,
            key: id,
            children: saintsDescription(i)
        };
    }) : [];

   
    return (
        <>
            {showSaint ?
                <>
                    <Button onClick={() => setShowSaint(false)}>Go Back</Button><br /><br />
                    {saints[value.month()] !== null && saints[value.month()].length > 0 ? <div className="card-container">
                        <Tabs type="card" items={items} />
                    </div> : <></>}
                </>
                :
                <>
                    <Calendar dateCellRender={dateCellRender} monthCellRender={monthCellRender} value={value} onSelect={onSelect} onPanelChange={onPanelChange} />
                    <Row>
                        <Col span={12}>
                            <Alert message={`Selected Day: ${selectedValue?.format('DD-MMM-YYYY')}`} />
                        </Col>
                        <Col span={6}>
                            {saintsStatus === 'success' && saints[value.month()] !== null && saints[value.month()].length > 0 ? <Button style={{ margin: '5px 0 0 10px' }} onClick={() => setShowSaint(true)}>Show Saints</Button> : <></>}
                        </Col>
                        <Col span={6}>
                            {saintsStatus === 'loading' ? <Spin indicator={loadingIcon} /> : <></>}
                        </Col>
                    </Row>
                </>
            }
            <br /><br />
        </>
    )
}

export default Saints;
/*

{(saints[value.month()] != null && saints[value.month()].length > 0) ?
                        <Tabs type='card' size='small' items={saints[value.month()].map((e, index) => {return {label: `Day ${index+1, key: index, children: saintsDescription(index)}`}})}></Tabs>
                        : []}

*/