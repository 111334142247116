import React from "react";
import { Row, Col, Descriptions, Divider } from 'antd';

function Pride() {
    return (<>
        <Row>
            <Col span={18}>
                <h3 style={{ color: 'midnightblue' }}>Prayer Against Pride - To St.Michael The Archangel</h3>
                <p>
                    St. Michael the Archangel, defend us in battle,<br />
                    be our protection against the wickedness and snares of the devil. <br />
                    May God rebuke him we humbly pray; and do thou, O Prince of the Heavenly host, by the power of God, <br />
                    cast into hell Satan and all the evil spirits who prowl about the world seeking the ruin of souls.<br />
                    Amen.
                </p>
            </Col>
            <Col span={6}>
                <img alt="St.Michael" src={require('../StMichael.jpg')} style={{ height: '150px', width: '198px', padding: '5px' }}></img>
            </Col>
        </Row>
        <Divider />
        <Descriptions title="Illustration - I Maccabees 1:1-9">
            <Descriptions.Item>
                <p>
                    1 Alexander of Macedon son of Philip had come from the land of Kittim and defeated Darius king of Persians and Medes, whom he succeeded as ruler, at first of Hellas.<br />
                    2 He undertook manu campaings, gained possession of many fortresses, and put the local kings to death.<br />
                    3 So he advanced to the ends of the earth, plundering nation after nation; the earth grew silent before him,<br />
                    and <b>his ambitious heart swelled wih pride.</b><br />
                    4 He assembled very powerful forces and subdued provinces, nations and princes, and they became his tributaries.<br />
                    5 But the time came when Alexander took to his bed, in the knowledge that he was dying.<br />
                    6 He summoned his officers, nobleman who had been brought up with him from his youth, and divided his kingdom among them while he was still alive.<br />
                    7 Alexander had reigned twelve years when he died.<br />
                    8 Each of his officers established himself in his own region.<br />
                    9 All assumed crowns after his death, they and their heirs after them for many years, bringing increasing evils on the world.
                </p>
            </Descriptions.Item>
        </Descriptions>
        <Descriptions title="Ecclesiasticus/Sirach 35:17-23">
            <Descriptions.Item>
                17 The prayer of the humble pierces the clouds: and until it does, he is not to be consoled,<br />
                18 nor will he desist until the Most High takes notice of him, acquits the upright and delivers judgement.<br />
                19 And the Lord will not be slow, nor will he be dilatory on their behalf,<br />
                20 until he has crushed the loins of the merciless and exacted vengeance on the nations,<br />
                21 until he has eliminated the hordes of the arrogant and broken the sceptres of the wicked,<br />
                22 until he has repaid all people as their deeds deserve and human actions as their intentions merit,<br />
                23 until he has judged the case of his people and made them rejoice in his mercy.
            </Descriptions.Item>
        </Descriptions>
        <Descriptions title="Isaiah 25:11">
            <Descriptions.Item>
                ...But he will humble his pride despite what his hands may attempt.
            </Descriptions.Item>
        </Descriptions>
        <br /><br />
    </>);
}
export default Pride;