import React from "react"
import { sign, intention, amen } from "../Novena"

export const ThereseItems = [
    { label: 'Day 1', key: 1, children: <Therese props={[{ key: 1 }]} /> },
    { label: 'Day 2', key: 2, children: <Therese props={[{ key: 2 }]} /> },
    { label: 'Day 3', key: 3, children: <Therese props={[{ key: 3 }]} /> },
    { label: 'Day 4', key: 4, children: <Therese props={[{ key: 4 }]} /> },
    { label: 'Day 5', key: 5, children: <Therese props={[{ key: 5 }]} /> },
    { label: 'Day 6', key: 6, children: <Therese props={[{ key: 6 }]} /> },
    { label: 'Day 7', key: 7, children: <Therese props={[{ key: 7 }]} /> },
    { label: 'Day 8', key: 8, children: <Therese props={[{ key: 8 }]} /> },
    { label: 'Day 9', key: 9, children: <Therese props={[{ key: 9 }]} /> }
]

const var1 = 'Dearest Saint Therese of Lisieux, you said that you would spend your time in heaven doing good on earth.'
const var2 = 'Your trust in God was complete. Pray that He may increase my trust in His goodness and mercy as I ask for the following petitions…'
const var3 = `Pray for me that I, like you, may have great and innocent confidence in the loving promises of our God. Pray that I may live my life in union with God’s plan for me, and one day see the Face of God whom you loved so deeply.`
const var4 = `Saint Therese, you were faithful to God even unto the moment of your death. Pray for me that I may be faithful to our loving God. May my life bring peace and love to the world through faithful endurance in love for God our savior.`
const var5 = `Glory be to the Father, to the Son, and to the Holy Spirit. As it was in the beginning, is now, and ever shall be, world without end.`

const word1 = `Loving God, you blessed St. Therese with a capacity for a great love. Help me to believe in your unconditional love for each of your children, especially for me.`
const word2 = `Loving God, you loved St. Therese’s complete trust in your care. Help me to rely on your providential care in each circumstance of my life, especially the most difficult and stressful.`
const word3 = `Loving God, you gave St. Therese the ability to see You in the ordinary routine of each day. Help me to be aware of your presence in the everyday events of my life.`
const word4 = `Loving God, You taught St. Therese how to find You through the “little way” of humility and simplicity. Grant that I may never miss the grace hidden in humble service to others.`
const word5 = `Loving God, You gave St. Therese the gift of forgiving others even when she felt hurt and betrayed. Help me to be able to forgive others who have wounded me, especially…`
const word6 = `Loving God, St. Therese experienced every day as a gift from You. She saw it as a time to love You through other people. May I, too, see every day as an opportunity to say yes to You.`
const word7 = `Loving God, St. Therese offered to You her weakness. Help me to see in my weakness an opportunity to rely completely on you.`
const word8 = `Loving God, You loved St. Therese with a powerful love and made her a source of strength to those who had lost faith in You. Help me to pray with confidence for those in my life who do not believe they can be loved.`
const word9 = `Loving God, St. Therese never doubted that her life had meaning. Help me to see how I can bless and love everyone in my life. Especially…`

const str1 = 'I love you, Lord. Help me to love you more!'
const str2 = 'I trust you, Lord. Help me to trust you more!'
const str3 = 'I see you, Lord. Help me to see you more!'
const str4 = 'I am humble, Lord. Give me more humility!'
const str5 = 'I try to forgive, Lord. Help me to forgive 70 times 7 times!'
const str6 = 'I accept your will, Lord. Help me to accept your will every day!'
const str7 = 'I rely on you, Lord. Help me to rely on you more!'
const str8 = 'I reflect you to the world, Lord. Help me to reflect you more clearly!'
const str9 = 'I love your people, Lord. Help me to love them more!'

function setWord(key) {
    switch (key) {
        case 1:
            return word1 + ' ' + str1;
        case 2:
            return word2 + ' ' + str2 + ' ' + str1;
        case 3:
            return word3 + ' ' + str3 + ' ' + str2 + ' ' + str1;
        case 4:
            return word4 + ' ' + str4 + ' ' + str3 + ' ' + str2 + ' ' + str1;
        case 5:
            return word5 + ' ' + str5 + ' ' + str4 + ' ' + str3 + ' ' + str2 + ' ' + str1;
        case 6:
            return word6 + ' ' + str6 + ' ' + str5 + ' ' + str4 + ' ' + str3 + ' ' + str2 + ' ' + str1;
        case 7:
            return word7 + ' ' + str7 + ' ' + str6 + ' ' + str5 + ' ' + str4 + ' ' + str3 + ' ' + str2 + ' ' + str1;
        case 8:
            return word8 + ' ' + str8 + ' ' + str7 + ' ' + str6 + ' ' + str5 + ' ' + str4 + ' ' + str3 + ' ' + str2 + ' ' + str1;
        case 9:
            return word9 + ' ' + str9 + ' ' + str8 + ' ' + str7 + ' ' + str6 + ' ' + str5 + ' ' + str4 + ' ' + str3 + ' ' + str2 + ' ' + str1;
        default:
            return;
    }
}

function Therese(data) {
    return (
        <p>
            {sign}<br /><br />

            {var1}<br /><br />
            {var2}<br />

            <br /><i>{intention}</i><br /><br />

            {var3}<br /><br />
            {var4}<br />

            {data != null && data.props != null && data.props[0] != null && data.props[0].key != null ? <> <br />{setWord(data.props[0].key)}<br /><br /></> : <br />}

            {var5}<br />

            {amen}<br /><br />
            {sign}<br />
        </p>
    )
}