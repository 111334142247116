import React from "react";
import { Descriptions } from 'antd';

function NoFear() {
    return (
        <>
            <Descriptions title="Sirach 4:17,18">
                <Descriptions.Item>
                    17 for though she takes him at first through winding ways, bringing fear and faintness on him, trying him out with her discipline till she can trust him, and testing him with her ordeals,<br />
                    18 she then comes back to him on the straight road, makes him happy and reveals her secrets to him.<br />
                </Descriptions.Item>
            </Descriptions>
            <Descriptions title="Psalms 27">
                <Descriptions.Item>
                    Of David<br />
                    1 Yahweh is my light and my salvation, whom should I fear? Yahweh is the fortress of my life, whom should I dread?<br />
                    2 When the wicked advance against me to eat me up, they, my opponents, my enemies, are the ones who stumble and fall.<br />
                    3 Though an army pitch camp against me, my heart will not fear, though war break out against me, my trust will never be shaken.<br />
                    4 One thing I ask of Yahweh, one thing I seek: to dwell in Yahweh's house all the days of my life, to enjoy the sweetness of Yahweh, to seek out his temple.<br />
                    5 For he hides me away under his roof on the day of evil, he folds me in the recesses of his tent, sets me high on a rock.<br />
                    6 Now my head is held high above the enemies who surround me; in his tent I will offer sacrifices of acclaim. I will sing, I will make music for Yahweh.<br />
                    7 Yahweh, hear my voice as I cry, pity me, answer me!<br />
                    8 Of you my heart has said, 'Seek his face!' Your face, Yahweh, I seek;<br />
                    9 do not turn away from me. Do not thrust aside your servant in anger, without you I am helpless. Never leave me, never forsake me, God, my Saviour.<br />
                    10 Though my father and mother forsake me, Yahweh will gather me up.<br />
                    11 Yahweh, teach me your way, lead me on the path of integrity because of my enemies;<br />
                    12 do not abandon me to the will of my foes -- false witnesses have risen against me, and are breathing out violence.<br />
                    13 This I believe: I shall see the goodness of Yahweh, in the land of the living.<br />
                    14 Put your hope in Yahweh, be strong, let your heart be bold, put your hope in Yahweh.<br />
                </Descriptions.Item>
            </Descriptions>
            <Descriptions title="Psalms 28">
                <Descriptions.Item>
                    Of David<br />
                    1 To you, Yahweh, I cry, my rock, do not be deaf to me! If you stay silent I shall be like those who sink into oblivion.<br />
                    2 Hear the sound of my prayer when I call upon you, when I raise my hands, Yahweh, towards your Holy of Holies.<br />
                    3 Do not drag me away with the wicked, with evil-doers, who talk to their partners of peace with treachery in their hearts.<br />
                    4 Repay them as their deeds deserve, as befits their treacherous actions; as befits their handiwork repay them, let their deserts fall back on themselves.<br />
                    5 They do not comprehend the deeds of Yahweh, the work of his hands. May he pull them down and not rebuild them!<br />
                    6 Blessed be Yahweh for he hears the sound of my prayer.<br />
                    7 Yahweh is my strength and my shield, in him my heart trusts. I have been helped; my body has recovered its vigour, with all my heart I thank him.<br />
                    8 Yahweh is the strength of his people, a safe refuge for his anointed.<br />
                    9 Save your people, bless your heritage, shepherd them and carry them for ever!<br />
                </Descriptions.Item>
            </Descriptions>
            <br /><br />
        </>
    )
}
export default NoFear;