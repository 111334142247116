import React from "react";
import { Descriptions } from 'antd';

function Speech() {
    return (
        <>
            <Descriptions title="Sirach 32:8">
                <Descriptions.Item>
                    Keep to the point, say much in few words; give the impression of knowing but not wanting to speak.
                </Descriptions.Item>
            </Descriptions>
            <Descriptions title="">
                <Descriptions.Item>

                </Descriptions.Item>
            </Descriptions>
        </>
    )
}

export default Speech;