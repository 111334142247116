import React from "react";
import { Descriptions } from 'antd';

function Tongue() {
    return (<>
        <Descriptions title="Ezekial 3:26,27">
            <Descriptions.Item>
                26 I am going to make your tongue stick to the roof of your mouth; you will be dumb, and no longer able to reprove them, for they are a tribe of rebels.<br />
                27 When I speak to you, however, I shall open your mouth and then you will say to them, <br />"Lord Yahweh says this: Let anyone prepared to listen, listen; let anyone who refuses, refuse!" - for they are a tribe of rebels.
            </Descriptions.Item>
        </Descriptions>
        <Descriptions title="Jeremiah 15:19">
            <Descriptions.Item>
                To which Yahweh replied, 'If you repent, I shall restore you to plead before me. <br />If you distinguish between the precious and the base, you shall be as my own mouth. <br />They will come back to you but you must not go back to them.'
            </Descriptions.Item>
        </Descriptions>
        <Descriptions title="Sirach 42:20">
            <Descriptions.Item>
                Not a thought escapes Him, not a single word is hidden from Him.
            </Descriptions.Item>
        </Descriptions>
        <Descriptions title="Isaiah 52:11">
            <Descriptions.Item>
                Go away, go away, leave that place, do not touch anything unclean.  Get out of her, purify yourselves, you who carry Yahweh's vessels!
            </Descriptions.Item>
        </Descriptions>
    </>);
}
export default Tongue;