import React from "react";
import { Descriptions } from 'antd';

function Strength() {
    return (
        <>
            <Descriptions title="Psalms 34">
                <Descriptions.Item>
                    (Of David, when he had feigned insanity before Abimelech, and Abimelech sent him away)<br/><br/>
                    1 I will bless Yahweh at all times, His praise continually on my lips.<br />
                    2 I will praise Yahweh from my heart; let the humble hear and rejoice.<br />
                    3 Proclaim with me the greatness of Yahweh, let us acclaim His name together.<br />
                    4 I seek Yahweh and He answers me, frees me from all my fears.<br />
                    5 Fix your gaze on Yahweh and your face will grow bright, you will never hang your head in shame.<br />
                    6 A pauper calls out and Yahweh hears, saves him from all his troubles.<br />
                    7 The angle of Yahweh encamps around those who fear him, and rescues them.<br />
                    8 Taste and see that Yahweh is good. How blessed are those who take refuge in him.<br />
                    9 Fear Yahweh, You his holy ones; those who fear Him lack for nothing.<br />
                    10 Young lions may go needy and hungry, but those who seek Yahweh lack nothing good.<br />
                    11 Come, my children, listen to me, I will teach you the fear of Yahweh.<br />
                    12 Who among you delights in life, longs for time to enjoy prosperity?<br />
                    13 Guard your tongue from evil, your lips from any breadth of deceit.<br />
                    14 Turn away from evil and do good, seek peace and purse it.<br />
                    15 The eyes of Yahweh are on the upright, His ear turned to their cry.<br />
                    16 But Yahweh's face is set against those who do evil, to cut off the memory of them from the earth.<br />
                    17 They cry in anguish and Yahweh hears, and resuces them from all their troubles.<br />
                    18 Yahweh is near to the broken-hearted, he helps those whose spirit is crushed.<br />
                    19 Though hardships without number beset the upright, Yahweh brings rescue from them all.<br />
                    20 Yahweh takes care of all their bones, not one of them will be broken.<br />
                    21 But to the wicked, evil brings death, those who hate the upright will pay the penalty.<br />
                    22 Yahweh ransoms the lives of those who serve Him, and there will be no penalty for those who take refuge in Him.
                </Descriptions.Item>
            </Descriptions>
            <Descriptions title="Ephesians 6:10-20">
                <Descriptions.Item>
                    10 Finally, grow strong in the Lord, with the strength of His power. <br />
                    11 Put on the full armour of God so as to be able to resist the devil's tactics. <br />
                    12 For it is not against human enemies that we have to struggle,
                    but against the principlaties and the ruling forces who are masters of the darkness in this world, the spirit of evil in the heavens.<br />
                    13 That is why you must take up all God's armour, or you will not be able to put up any resistance on the evil day, or stand your ground even though you exert yourselves to the full.<br />
                    14 So stand your ground, with truth a belt around your waist, and uprightness a breastplate, <br />
                    15 wearing for shoes on your feet the eagerness to spread the gospel of peace <br />
                    16 and always carrying the shield of faith so that you can use it to quench the burning arrows of the evil one. <br />
                    17 And then you must take salvation as your helmet and the sword of the Spirit, that is, the word of God.<br />
                    18 In all your prayer and entreaty keep praying in the Spirit on every possible occasion. Never get tired of staying awake to pray for all God's holy people,<br />
                    19 and pray for me to be given an opportunity to open my mouth and fearlessly make known the mystery of the gospel <br />
                    20 of which I am am ambassador in chains; pray that in proclaiming it I am speak as fearlessly as I ought to.
                </Descriptions.Item>
            </Descriptions>
            <Descriptions title="Phillipians 2:1-11">
                <Descriptions.Item>
                    1 So if in Christ there is anything that will move you, any incentive in love, any fellowship in the Spirit, any warmth or sympathy - I appeal to you,<br />
                    2 make my joy complete by being of a single mind, one in love and one in heart and one in mind. <br />
                    3 Nothing is to be done out of jealousy or vanity; instead out of humiilty of mind, everyone should give preference to others, <br />
                    4 everyone pursuing not selfish interests but those of others. <br />
                    5 make your mind the mind of Christ Jesus: <br />
                    6 Who, being in the form of God, did not count equality with God something to be grasped.<br />
                    7 But he emptied himself, taking the form of slave, becoming as human beings are; and being in every way like a human being,<br />
                    8 he was humbler yet, even to accepting death, death on a cross.<br />
                    9 And for this God raised Him high, and gave Him the name which is above all other names;<br />
                    10 so that all beings in the heavens, on earth and in the underworld should bend the knee at the name of Jesus<br />
                    11 and that every tongue should acknowledge Jesus Christ as Lord to the glory of God the Father.
                </Descriptions.Item>
            </Descriptions>
            <br/><br/><br/>
        </>
    )
}

export default Strength;