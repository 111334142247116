import React from "react";
import { Descriptions } from 'antd';

function InDanger() {
    return (
        <>
            <Descriptions title="Prayer in danger (Psalms 25)">
                <Descriptions.Item>
                    Of David<br />
                    1 ADORATION I offer, Yahweh,<br />
                    2 to you, my God. BUT in my trust in you do not put me to shame, let not my enemies gloat over me.<br />
                    3 CALLING to you, none shall ever be put to shame, but shame is theirs who groundlessly break faith.<br />
                    4 DIRECT me in your ways, Yahweh, and teach me your paths.<br />
                    5 ENCOURAGE me to walk in your truth and teach me since you are the God who saves me. FOR my hope is in you all day long -- such is your generosity, Yahweh.<br />
                    6 GOODNESS and faithful love have been yours for ever, Yahweh, do not forget them.<br />
                    7 HOLD not my youthful sins against me, but remember me as your faithful love dictates.<br />
                    8 INTEGRITY and generosity are marks of Yahweh for he brings sinners back to the path.<br />
                    9 JUDICIOUSLY he guides the humble, instructing the poor in his way.<br />
                    10 KINDNESS unfailing and constancy mark all Yahweh's paths, for those who keep his covenant and his decrees.<br />
                    11 LET my sin, great though it is, be forgiven, Yahweh, for the sake of your name.<br />
                    12 MEN who respect Yahweh, what of them? He teaches them the way they must choose.<br />
                    13 NEIGHBOURS to happiness will they live, and their children inherit the land.<br />
                    14 ONLY those who fear Yahweh have his secret and his covenant, for their understanding.<br />
                    15 PERMANENTLY my eyes are on Yahweh, for he will free my feet from the snare.<br />
                    16 QUICK, turn to me, pity me, alone and wretched as I am!<br />
                    17 RELIEVE the distress of my heart, bring me out of my constraint.<br />
                    18 SPARE a glance for my misery and pain, take all my sins away.<br />
                    19 TAKE note how countless are my enemies, how violent their hatred for me.<br />
                    20 UNLESS you guard me and rescue me I shall be put to shame, for you are my refuge.<br />
                    21 VIRTUE and integrity be my protection, for my hope, Yahweh, is in you.<br />
                    22 Ransom Israel, O God, from all its troubles.
                </Descriptions.Item>
            </Descriptions>
            <br /><br />
        </>
    )
}
export default InDanger;