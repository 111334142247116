import React from "react";
import { Descriptions } from 'antd';

function AgainstFear() {
    return (
        <>
            <Descriptions title="Isaiah 37:6">
                <Descriptions.Item>
                    Yahweh says this: Do not be afraid of the words which you have heard or the blasphemies which the King of Assyria's minions have uttered against me.
                </Descriptions.Item>
            </Descriptions>
            <Descriptions title="Isaiah 35:3,4">
                <Descriptions.Item>
                    3 Strengthen all weary hands, steady all trembling knees<br />
                    4 and say to the faint-hearted, 'Be strong! Do not be afraid. Here is your God, vengeance is coming, divine retribution; he is coming to save you.'
                </Descriptions.Item>
            </Descriptions>
        </>
    )
}

export default AgainstFear;