import React from "react";
import { Descriptions } from 'antd';

function Protection() {
    return (<>
        <Descriptions title="Psalms 91">
            <Descriptions.Item>
                1 You who live in the secret place of Elyon, spend your nights in the shelter of Shaddai,<br />
                2 saying to Yahweh, 'My refuge , my fortress, my God in whom I trust!'<br />
                3 He rescues you from the snare of the fowler set on destruction;<br />
                4 he covers you with his pinions, you find shelter under his wings. His constancy is shield and protection.<br />
                5 You need not fear the terrors of the night, the arrow that flies in the daytime.<br />
                6 the plague that stocks in the darkness, the scourge that wreaks havoc at high noon.<br />
                7 Though a thousand fall at your side, ten thousand at your right hand, you yoursef will remain unscathed.<br />
                8 You have only to keep your eyes open to see how the wicked are repaid, <br />
                9 you who say, 'Yahweh my refuge!' and make Elyon your fortress.<br />
                10 No disaster can overtake you, no plague come near you tent;<br />
                11 he has given his angels orders about you to guard you wherever you go.<br />
                12 They will carry you in their arms in case you trip over a stone.<br />
                13 You will walk upon wild beast and adder, you will trample young lions and snakes.<br />
                14 Since he clings to me I rescue him, I raise him high, since he acknowledges my name.<br />
                15 He calls to me and I answer him: in distress I am at his side, I rescue him and bring him honour.<br />
                16 I shall satisfy him with long life, and grant him to see my salvation.
            </Descriptions.Item>
        </Descriptions>
    </>);
}
export default Protection;