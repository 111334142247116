import React, { useState, useEffect } from "react";
import { Row, Col, Space, Card, Popover, Switch } from "antd";
import { ClockCircleTwoTone } from '@ant-design/icons';
import { timezones } from '../Helper/constants';
import "./DateTime.css";

function DateTime(){
    const [currentDateTime, setCurrentDateTime] = useState(new Date());

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentDateTime(new Date());
        }, 1000);
        return () => {
            clearInterval(timer);
        }
    }, [])

    const getDate = timezone => {
        let options = {
            timeZone: timezone,
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            weekday: 'long'
        },
            formatter = new Intl.DateTimeFormat([], options);

        return formatter.format(currentDateTime);
    }

    const getTime = timezone => {
        let options = {
            timeZone: timezone,
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            timeZoneName: 'short',
            hour12: false,
            dayPeriod: "short"
        },
            formatter = new Intl.DateTimeFormat([], options);

        return formatter.format(currentDateTime);
    }

    const showPopover = () => {
        return (
            <>
                <Popover content={displayClock()} trigger="click">
                <Switch className="switch-style" checkedChildren="Hide Clock" unCheckedChildren="Show Clock" defaultChecked={false}/>
                </Popover>
            </>
        )
    }

    const displayClock = () => {
        return <>
            <Row gutter={8}>
                <Col span={3}>
                    <Card title="PST (UTC-8)" bordered={false}>
                        <p>{getTime(timezones.PST)}</p>
                        <p>{getDate(timezones.PST)}</p>
                    </Card>
                </Col>
                <Col span={3}>
                    <Card title="MST (UTC-7)" bordered={false}>
                        <p>{getTime(timezones.MST)}</p>
                        <p>{getDate(timezones.MST)}</p>
                    </Card>
                </Col>
                <Col span={3}>
                    <Card title="CST (UTC-6)" bordered={false}>
                        <p>{getTime(timezones.CST)}</p>
                        <p>{getDate(timezones.CST)}</p>
                    </Card>
                </Col>
                <Col span={3}>
                    <Card title="EST (UTC-5)" bordered={false}>
                        <p>{getTime(timezones.EST)}</p>
                        <p>{getDate(timezones.EST)}</p>
                    </Card>
                </Col>
                <Col span={3}>
                    <Card title={<>UTC/GMT<Space><ClockCircleTwoTone twoToneColor="midnightblue" /></Space></>} bordered={false}>
                        <p>{getTime(timezones.UTC)}</p>
                        <p>{getDate(timezones.UTC)}</p>
                    </Card>
                </Col>
                <Col span={3}>
                    <Card title="EAT (UTC+3)" bordered={false}>
                        <p>{getTime(timezones.EAT)}</p>
                        <p>{getDate(timezones.EAT)}</p>
                    </Card>
                </Col>
                <Col span={3}>
                    <Card title="IST (UTC+5:30)" bordered={false}>
                        <p>{getTime(timezones.IST)}</p>
                        <p>{getDate(timezones.IST)}</p>
                    </Card>
                </Col>
                <Col span={3}>
                    <Card title="AWST (UTC+8)" bordered={false}>
                        <p>{getTime(timezones.AWST)}</p>
                        <p>{getDate(timezones.AWST)}</p>
                    </Card>
                </Col>
            </Row>
        </>
    }

    return (
        <>
        <p>{getTime(timezones.UTC)} , {getDate(timezones.UTC)}    {showPopover()}</p>
        </>
    )
}

export default DateTime;