import React from "react";
import { Descriptions } from 'antd';

function Assurance() {
    return (
        <>
            <Descriptions title="Job 17:3">
                <Descriptions.Item>
                    So you must go bail for me to yourself, for which of them cares to clap hus hand on mine?
                </Descriptions.Item>
            </Descriptions>
            <Descriptions title="Zephaniah 3:15">
                <Descriptions.Item>
                    Yahweh has replealed your sentence; he has turned your enemy away. Yahweh is king among you, Israel you have nothing more to fear.
                </Descriptions.Item>
            </Descriptions>
            <Descriptions title="Zephaniah 3:17">
                <Descriptions.Item>
                    Yahweh your God is there with you, the warrier-Saviour. He will rejoice over you with happy song, He will renew you by His love, He will dance with shouts of joy for you
                </Descriptions.Item>
            </Descriptions>
        </>
    )
}

export default Assurance;