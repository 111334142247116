import React from "react";
import { Descriptions } from "antd";

function Praise() {
    return (<>
        <Descriptions title="Psalms 118">
            <Descriptions.Item>
                Alleluia!<br />
                1 Give thanks to Yahweh for he is good, for His faithful love endures forever.<br />
                2 Let the house of Israel say, His faithful love endures for ever.<br />
                3 Let the house of Aaron say, 'His faithful love endures for ever.'<br />
                4 Let those who fear Yahweh say, 'His faithful love endures for ever.'<br />
                5 In my distress I called to Yahweh, he heard me and brouht me relief.<br />
                6 With Yahweh on my side I fear nothing; what can human beings do to me?<br />
                7 With Yahweh on my side as my help, I gloat over my enemies.<br />
                8 It is better to take refuge in Yahweh than to rely on human beings;<br />
                9 better to take refuge in Yahweh than to rely on princes.<br />
                10 Nations were swarming around me, in the name of Yahweh I cut them down.<br />
                11 they swarmed around me, pressing upon me, in the name of Yahweh I cut them down.<br />
                12 They swarmed around me like bees, they flared up like a brushwood fire, in the name of Yahweh I cut them down.<br />
                13 I was pushed hard, to make me fall, but Yahweh came to my help.<br />
                14 Yahweh is my strength and my song, he has been my Saviour.<br />
                15 Shouts of joy and salvation in the tents of the upright, 'Yahweh's right hand is triumphant,<br />
                16 Yahweh's right hand is victorious, Yahweh's right hand is triumphant!'<br />
                17 I shall not die, I shall live to recount the deeds of Yahweh.<br />
                18 Though Yahweh punished me sternly, he has not abandoned me to death.<br />
                19 Open for me the gates of saving justice, I shall go in and thank Yahweh.<br />
                20 This is the gate of Yahweh where the upright go in.<br />
                21 I thank You for hearing me and making Yourself my Saviour.<br />
                22 The stone which the builders rejected has become the cornerstone;<br />
                23 This is Yahweh's doing, and we marvel at it.<br />
                24 This is the day which Yahweh has made, a day for us to rejoice and be glad.<br />
                25 We beg You Yahweh, save us, we beg You Yahweh, give us victory!<br />
                26 Blessed in the name Yahweh is he who is coming! We bless You from the house of Yahweh.<br />
                27 Yahweh is God, he gives us light. Link your processions, branches in hand, upto to the horns of the altar.<br />
                28 You are my God, I thank You for hearing me, and making Yourself my Saviour.<br />
                29 Give thanks to Yahweh for He is good, for His faithful love endures forever.
            </Descriptions.Item>
        </Descriptions>
        <br /><br /><br />
    </>);
}
export default Praise;