import React from "react"
import { sign, intention, amen } from "../Novena"

export const PeregrineItems = [
    { label: 'Day 1', key: 1, children: <Peregrine props={[{key:1}]} /> },
    { label: 'Day 2', key: 2, children: <Peregrine props={[{key:2}]} /> },
    { label: 'Day 3', key: 3, children: <Peregrine props={[{key:3}]} /> },
    { label: 'Day 4', key: 4, children: <Peregrine props={[{key:4}]} /> },
    { label: 'Day 5', key: 5, children: <Peregrine props={[{key:5}]} /> },
    { label: 'Day 6', key: 6, children: <Peregrine props={[{key:6}]} /> },
    { label: 'Day 7', key: 7, children: <Peregrine props={[{key:7}]} /> },
    { label: 'Day 8', key: 8, children: <Peregrine props={[{key:8}]} /> },
    { label: 'Day 9', key: 9, children: <Peregrine props={[{key:9}]} /> }
]

const var1 = 'Dear holy servant of God, St. Peregrine, we pray today for healing.'
const var2 = 'Intercede for us! God healed you of cancer and others were healed by your prayers. Please pray for the physical healing of…'
const var3 = 'These intentions bring us to our knees seeking your intercession for healing.'
const var4 = 'We are humbled by our physical limitations and ailments. We are so weak and so powerless. We are completely dependent upon God. And so, we ask that you pray for us…'
const var5 = 'We know, St. Peregrine, that you are a powerful intercessor because your life was completely given to God. We know that in as much as you pray for our healing, you are praying even more for our salvation.'
const var6 = 'A life of holiness like yours is more important than a life free of suffering and disease. Pray for our healing, but pray even more that we might come as close to Our Lord as you are.'
const var7 = 'Glory be to the Father and to the Son and to the Holy Spirit, as it was in the beginning is now and ever shall be, world without end.'

const word1 = 'Pray for us, that we will not let sickness bring us to despair!'
const word2 = 'Pray for us, that we may persevere in hope!'
const word3 = 'Pray for us, that we will have the courage to offer up our suffering in unity with the Cross!'
const word4 = 'Pray for us, that the loneliness of our suffering will be consoled!'
const word5 = 'Pray for us, that the fear of death will be replaced with the hope of everlasting life!'
const word6 = 'Pray for us, that our suffering will not rob us of joy!'
const word7 = 'Pray for us, that in our pain we will not become selfish but ever more selfless!'
const word8 = 'Pray for us, that this sickness will teach me to depend more and more on God!'
const word9 = 'Pray for us, that our lives will glorify God alone!'

function setWord(key) {
    switch (key) {
        case 1:
            return word1;
        case 2:
            return word2;
        case 3:
            return word3;
        case 4:
            return word4;
        case 5:
            return word5;
        case 6:
            return word6;
        case 7:
            return word7;
        case 8:
            return word8;
        case 9:
            return word9;
        default:
            return;
    }
}

function Peregrine(data) {
    return (
        <p>
            {sign}<br /><br />

            {var1}<br /><br/>
            {var2}<br />

            <br /><i>{intention}</i><br /><br />

            {var3}<br />
            {var4}<br />

            {data!=null&&data.props!=null&& data.props[0]!=null && data.props[0].key!=null ? <> <br/>{setWord(data.props[0].key)}<br /><br/></> : <br/>} 

            {var5}<br /><br/>
            {var6}<br /><br/>
            {var7}<br /><br/>

            {amen}<br /><br />
            {sign}<br />
        </p>
    )
}